import { BasicLabelledObject } from './basic-labelled-object.model';
import { IncidentType } from './incident-type.model';
import { SeverityLevel } from './severity-level.model';
import { Station } from './station.model';

// tslint:disable:variable-name
export class Incident {

  id: number;
  station: Station;
  incident_type: IncidentType;
  severity_level: SeverityLevel;
  matrice_description?: string;
  status: BasicLabelledObject;
  declaration_date: string;
  declaration_time: string;
  comment: string;

  // Only for UI purposes => for export
  selected?: boolean;

  constructor(data = {}) {
    Object.assign(this, data);
    this.station = new Station(this.station);
    this.incident_type = new IncidentType(this.incident_type);
    this.severity_level = new SeverityLevel(this.severity_level);
    this.status = new BasicLabelledObject(this.status);
  }
}


export interface IncidentInterface {
  comment: string;
  declaration_date: string;
  declaration_time: string;
  id: number | null;
  incidentTypeLabel: string;
  incident_type_id: number;
  severityLevelDescription: string;
  severityLevelLabel: string;
  severity_level_id: number;
  station_id: number;
  created_at: Date;
}