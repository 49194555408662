<h3 class="mt-1 mb-2 text-center">
  {{contactData?.contact.name}}
</h3>

<mat-nav-list>
  <ng-container *ngIf="contactData.contact.cellphone">
    <!-- TODO: Add &body=... in link -->
    <a mat-list-item target="_blank" [href]="'sms:'+contactData.contact.cellphone" *ngIf="displaySms()"
      (click)="clickToCall('sms', contactData.contact.id)">
      <div mat-line>
        <mat-icon color="primary" class="mr-3">sms</mat-icon>
        <div>
          <div>{{'general.contact_methods.sms' | translate}}</div>
          <div class="text light">{{contactData.contact.cellphone}}</div>
        </div>
      </div>
    </a>
    <a mat-list-item target="_blank" [href]="'tel:'+contactData.contact.cellphone" *ngIf="displayTel()"
      (click)="clickToCall('cellphone', contactData.contact.id)">
      <div mat-line>
        <mat-icon color="primary" class="mr-3">smartphone</mat-icon>
        <div>
          <div>{{'general.contact_methods.phone' | translate}}</div>
          <div class="text light">{{contactData.contact.cellphone}}</div>
        </div>
      </div>
    </a>
  </ng-container>
  <ng-container *ngIf="contactData.contact.email">
    <a mat-list-item target="_blank" [href]="'mailto:'+contactData.contact.email" *ngIf="displayEmail()"
      (click)="clickToCall('email', contactData.contact.id)">
      <div mat-line>
        <mat-icon color="primary" class="mr-3">email</mat-icon>
        <div>
          <div>{{'general.contact_methods.email' | translate}}</div>
          <div class="text light">{{contactData.contact.email}}</div>
        </div>
      </div>
    </a>
  </ng-container>
  <ng-container *ngIf="displayMultiEmail()">
    <a mat-list-item target="_blank" [href]="'mailto:'+getMultiMailsList(contactData.contact, ';')">
      <div mat-line>
        <mat-icon color="primary" class="mr-3">dynamic_feed</mat-icon>
        <div class="overflow-hidden">
          <div>{{'general.contact_methods.multi_email' | translate}}</div>
          <div class="ellipsable text light">{{getMultiMailsList(contactData.contact, ', ')}}</div>
        </div>
      </div>
    </a>
  </ng-container>
</mat-nav-list>
