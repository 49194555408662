import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { Station } from './../../../../models/station.model';
import { StationService } from './../../services/station.service';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-grouped-contact',
  templateUrl: './grouped-contact.component.html',
  styleUrls: ['./grouped-contact.component.scss']
})
export class GroupedContactComponent implements OnInit {

  searchStationsForm: FormGroup;

  searchStationsResults: Station[];
  isLoadingResults = false;

  constructor(
    private fb: FormBuilder,
    private stationService: StationService,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,
    public platform: Platform,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  clearSearch(formControlName: string): void {
    this.searchStationsForm.get(formControlName).reset();
  }

  searchStations(): void {
    this.isLoadingResults = true;

    this.sharedService.setSearch(this.sharedService.SEARCH_GROUPED_CONTACT_LS_KEY_SUFFIX, this.searchStationsForm.value);

    this.stationService.searchForContact(this.searchStationsForm.value).subscribe(
      (stations: Station[]) => {
        this.searchStationsResults = stations;
      },
      (error) => { this.isLoadingResults = false; },
      () => { this.isLoadingResults = false; });
  }

  getLinkForGroupedcontact(type: 'sms' | 'email'): SafeUrl {
    let link = (type === 'sms') ? 'sms:' : 'mailto:';
    let separator = ';';
    if(this.platform.IOS && (type === 'sms'))
    {
      link = 'sms:/open?addresses=';
      separator = ',';
    }

    this.searchStationsResults.forEach((station: Station) => {
      const contactInfo = (type === 'sms') ? station.info.manager_phone : station.info.email;
      link += contactInfo ? (contactInfo + separator) : '';
    });

    // remove last ';'
    if(link.lastIndexOf(separator) > 0 ) {
      link = link.slice(0, link.lastIndexOf(separator));
    }

    return this.sanitizer.bypassSecurityTrustUrl(link);
  }

  getLinkForGroupedSms(): SafeUrl {
    return this.getLinkForGroupedcontact('sms');
  }

  getLinkForGroupedMail(): SafeUrl {
    return this.getLinkForGroupedcontact('email');
  }

  private initForm(): void {
    const savedSearches = this.sharedService.getSearches();
    const savedQuery = savedSearches[this.sharedService.SEARCH_GROUPED_CONTACT_LS_KEY_SUFFIX];

    this.searchStationsForm = this.fb.group({
      sales_channel: [savedQuery?.sales_channel],
      zip_or_department: [savedQuery?.zip_or_department],
      city: [savedQuery?.city]
    });

    const hasSavedQuery = savedQuery && [savedQuery.sales_channel, savedQuery.zip_or_department, savedQuery.city].some(val => val !== null);
    if (hasSavedQuery) {
      this.searchStations();
    }

    this.searchStationsForm.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => {
      this.searchStations();
    });
  }

}
