import { Platform } from '@angular/cdk/platform';
import { Component, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Contact } from 'src/app/models/contact.model';
import { SelectableContact } from './../../../../../models/selectable-contact.model';
import { Station } from './../../../../../models/station.model';
import { ClickToCallService } from './../../../../shared/services/click-to-call.service';
import { DatetimeHelperService } from './../../../../shared/services/datetime-helper.service';
import { ContactService } from './../../../services/contact.service';
import { IncidentService } from '../../../services/incident.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-incident-contacts-list',
  templateUrl: './incident-contacts-list.component.html',
  styleUrls: ['./incident-contacts-list.component.scss']
})
export class IncidentContactsListComponent implements OnInit {

  private readonly NEW_LINE = '%0D%0A';

  @Input() station: Station;
  @Input() contacts: Contact[];
  @Input() incidentData: any;
  @Input() callbackCreateIncident: (args: string, incidentService, snackBar) => Observable<number>;

  actionType:string;

  telContacts: SelectableContact[];
  smsContacts: SelectableContact[];
  emailContacts: SelectableContact[];

  constructor(
    private incidentService: IncidentService,
    private contactService: ContactService,
    private clickToCallService: ClickToCallService,
    private translate: TranslateService,
    private datetimeHelper: DatetimeHelperService,
    private sanitizer: DomSanitizer,
    public platform: Platform,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.telContacts = this.contacts.filter(contact => contact.contact_type === 'tel').map(contact => new SelectableContact(contact));
    this.smsContacts = this.contacts.filter(contact =>
      contact.contact_type === 'tel' || contact.contact_type === 'sms'
    ).map(contact => new SelectableContact(contact));
    this.emailContacts = this.contacts.filter(contact => contact.contact_type === 'email').map(contact => new SelectableContact(contact));
  }

  updateFavoriteContact(event: Event, contactId: number, type: 'sms' | 'email'): void {
    event.stopPropagation();

    this.contactService.updateFavorite(contactId).subscribe(
      (isFavorite: boolean) => {
        const contacts = this.getContactsOfType(type);
        const contact = contacts.find(contactItem => contactItem.id === contactId);
        contact.is_favorite = isFavorite;
      },
      (error) => { }
    );
  }

  selectContactsOfType(type: 'sms' | 'email', selectAll: boolean): void {
    this.getContactsOfType(type)?.forEach(contact => contact.selected = selectAll);
  }

  getSelectedContactsOfTypeCount(type: 'sms' | 'email'): number {
    const selectedContactsOfType = this.getSelectedContactsOfType(type);
    return selectedContactsOfType ? selectedContactsOfType.length : 0;
  }

  getLinkForContactsOfType(type: 'sms' | 'email'): SafeUrl {
    const selectedContactsOfType = this.getSelectedContactsOfType(type);
    const bodyPrefixForSms = this.platform.IOS ? '&body=' : '?body=';
    let link = (type === 'sms') ? 'sms:' : 'mailto:';
    let separator = ';';
    if (this.platform.IOS && (type === 'sms')) {
      link = 'sms:/open?addresses=';
      separator = ',';
    }

    selectedContactsOfType.forEach(contact => {
      const contactInfo = (type === 'sms') ? contact.cellphone : contact.email;
      link += contactInfo ? (contactInfo + separator) : '';
    });

    // remove last ';'
    if (link.lastIndexOf(separator) > 0) {
      link = link.slice(0, link.lastIndexOf(separator));
    }

    let body: string;
    if (type === 'sms') {
      body = bodyPrefixForSms;
    } else {
      const subject = `?subject=${this.incidentData.incidentTypeLabel}`;
      body = `${subject}&body=`;
    }

    body = this.populateContactLinkBody(body);

    return this.sanitizer.bypassSecurityTrustUrl(link + body);
  }

  updateClickToCallContacts(type: 'sms' | 'email', $event: PointerEvent): void {
    const smsContactsIds = this.smsContacts.filter(contact => contact.selected).map(contact => contact.id);
    const emailContactsIds = this.emailContacts.filter(contact => contact.selected).map(contact => contact.id);
    const contactIds = type === 'sms' ? smsContactsIds : emailContactsIds;
    this.actionType = $event.type;
    this.clickToCall(contactIds, type);
  }

  telClickToCall(value: { channel: string, contactId: number }): void {
    this.clickToCall([value.contactId], value.channel);
  }

  private clickToCall(contactIds: number[], channel: string): void {
    let targetIncidentId = this.callbackCreateIncident(this.actionType, this.incidentService, this.snackBar);
    targetIncidentId.subscribe(tempIncidentId => this.clickToCallService.add(this.station.id, channel, tempIncidentId, contactIds).subscribe(() => {}))
  }

  private populateContactLinkBody(bodyPrefix: string): string {
    let body = bodyPrefix;

    const managerName = this.station.info.manager_last_name ?
      this.station.info.manager_first_name + ' ' + this.station.info.manager_last_name.toLocaleUpperCase() : ' - ';
    const manager2Name = this.station.info.manager_2_last_name ?
      this.station.info.manager_2_first_name + ' ' + this.station.info.manager_2_last_name.toLocaleUpperCase() : ' - ';

    body += this.translate.instant('incident.contact_media.title');
    body += this.NEW_LINE + this.NEW_LINE;
    body += this.station.info.sales_channel.label + this.NEW_LINE;
    body += this.station.info.site_name.trim() + this.NEW_LINE;
    body += this.station.code_implant + this.NEW_LINE;
    body += this.station.info.zipcode + ' ' + this.station.info.city + this.NEW_LINE;
    body += this.station.info.street + this.NEW_LINE;
    body += this.translate.instant('incident.contact_media.manager') + managerName + this.NEW_LINE;
    body += this.translate.instant('incident.contact_media.manager_2') + manager2Name;
    body += this.NEW_LINE + this.NEW_LINE;

    const incidentDate = this.datetimeHelper.getFormattedDateStringFromISOString(this.incidentData.declaration_date, this.incidentData.declaration_time);

    body += this.translate.instant('incident.contact_media.incident_date') + this.NEW_LINE + incidentDate;
    body += this.NEW_LINE + this.NEW_LINE;

    const incidentCreationDate = this.datetimeHelper.getFormattedDate(this.incidentData.created_at);

    body += this.translate.instant('incident.contact_media.date') + this.NEW_LINE + incidentCreationDate;
    body += this.NEW_LINE + this.NEW_LINE;

    body += this.incidentData.incidentTypeLabel + ' : ' + this.NEW_LINE;
    body += this.translate.instant('incident.incident_severity') + ' ' + this.incidentData.severityLevelLabel;
    body += ' : ' + this.incidentData.severityLevelDescription;

    if (this.incidentData.comment) {
      let comment = this.incidentData.comment.replace(/\n/g, this.NEW_LINE);
      body += this.NEW_LINE + this.NEW_LINE +
        this.translate.instant('incident.contact_media.comment') + this.NEW_LINE + comment;
    }

    return body;
  }

  private getSelectedContactsOfType(type: 'sms' | 'email'): Contact[] {
    if (type === 'sms') {
      return this.smsContacts?.filter(smsContact => smsContact.selected);
    }
    if (type === 'email') {
      return this.emailContacts?.filter(emailContact => emailContact.selected);
    }
  }

  private getContactsOfType(type: 'sms' | 'email'): SelectableContact[] {
    if (type === 'sms') { return this.smsContacts; }
    if (type === 'email') { return this.emailContacts; }
  }
}
