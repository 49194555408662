import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() menuClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public userService: UserService,
    public authService: AuthService
  ) {
  }

  ngOnInit(): void {
  }

  toggleNav(): void {
    this.menuClicked.emit();
  }
}
