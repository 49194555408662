import { Routes } from '@angular/router';
import { mainRoutesNames } from '../main/main.routes.names';
import { coreRoutesNames } from './core.routes.names';

export const routes: Routes = [

  {
    path: coreRoutesNames.MAIN,
    loadChildren: () => import('../main/main.module')
      .then(m => m.MainModule)
  },
  {
    path: '',
    loadChildren: () => import('../auth/auth.module')
      .then(m => m.AuthModule)
  },
  {
    path: '**',
    redirectTo: coreRoutesNames.MAIN + '/' + mainRoutesNames.HOME,
    pathMatch: 'full'
  }
];
