import { OperatorBottomSheetComponent } from './operator-bottom-sheet/operator-bottom-sheet.component';
import { ClickToCallService } from './../../../../../shared/services/click-to-call.service';
import { Component, Input, OnInit } from '@angular/core';
import { StationInfo } from './../../../../../../models/station-info.model';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-operator-tab',
  templateUrl: './operator-tab.component.html',
  styleUrls: ['./operator-tab.component.scss']
})
export class OperatorTabComponent implements OnInit {

  @Input() stationInfo: StationInfo;

  constructor(
    private clickToCallService: ClickToCallService,
    private bottomSheet: MatBottomSheet
  ) { }

  ngOnInit(): void {
  }

  // tslint:disable-next-line: variable-name
  clickToCall(channel: string, is_operator = false, is_manager_1 = false, is_manager_2 = false): void {
    this.clickToCallService.add(this.stationInfo.station_id, channel, null, null, null, {
      is_operator,
      is_manager_1,
      is_manager_2
    }).subscribe(() => {
      // Nothing to do here
    });
  }

  openOperatorBottomSheet(managerNumber: number): void {
    const sheetRef = this.bottomSheet.open(OperatorBottomSheetComponent, {
      data: { stationInfo: this.stationInfo, managerNumber }
    });

    sheetRef.afterDismissed().subscribe((data) => {
      if (!data) { return; }
      this.clickToCall(data.channel, false, managerNumber === 1, managerNumber === 2);
    })
  }

  openLink(link: string): void {
    link = link.replace('documents/', '');
    link = environment.api.host + '/getFile/' + link;
    window.open(link, '_blank');
  }
}
