<mat-tab-group>
  <mat-tab [label]="'incident.contacts_list.tel'|translate: {count: telContacts?.length}"
    *ngIf="telContacts.length > 0">
    <app-contacts-list [contacts]="telContacts" [onlyTel]="true" [ignoreIsDisplayed]="true"
      (clickToCalled)="telClickToCall($event)" [hideMultiEmail]="true"></app-contacts-list>
  </mat-tab>

  <!-- TODO: refactor => same code for sms/email extract to a new component -->
  <mat-tab [label]="'incident.contacts_list.sms'|translate: {count: smsContacts?.length}"
    *ngIf="smsContacts.length > 0">
    <div class="items-list">
      <div class="text-center" *ngIf="smsContacts?.length > 1">
        <button mat-flat-button color="primary" class="mr-1" (click)="selectContactsOfType('sms', true)">
          {{'general.buttons.select_all' | translate}}
        </button>
        <button mat-flat-button color="primary" class="ml-1" (click)="selectContactsOfType('sms', false)">
          {{'general.buttons.select_none' | translate}}
        </button>
      </div>
      <div *ngFor="let contact of smsContacts" class="item">
        <div class="d-flex justify-content-between align-content-end">
          <div class="details-row mt-1">
            <div class="item-name text" [matTooltip]="contact.name">{{contact.name}}
            </div>
            <div class="text light" [matTooltip]="contact.contact_function?.label">{{contact.contact_function?.label}}
            </div>
          </div>
          <div class="item-icons">
            <mat-icon class="favorite-star" (click)="updateFavoriteContact($event, contact.id, 'sms')">
              {{contact.is_favorite? 'star' : 'star_outline'}}
            </mat-icon>
            <mat-checkbox color="primary" [(ngModel)]="contact.selected"></mat-checkbox>
          </div>
        </div>
      </div>
      <div class="text-center mt-3">
        <button mat-flat-button color="primary" (click)="smsLink.click()"
          [disabled]="getSelectedContactsOfTypeCount('sms') < 1">
          {{'incident.contacts_list.send_by_sms' | translate: {count: getSelectedContactsOfTypeCount('sms')} }}
        </button>
        <a target="_blank" [href]="getLinkForContactsOfType('sms')" class="d-none" #smsLink
          (click)="updateClickToCallContacts('sms', $event)"></a>
      </div>
    </div>
  </mat-tab>

  <mat-tab [label]="'incident.contacts_list.email'|translate: {count: emailContacts?.length}"
    *ngIf="emailContacts.length > 0">
    <div class="items-list">
      <div class="text-center" *ngIf="emailContacts?.length > 1">
        <button mat-flat-button color="primary" class="mr-1" (click)="selectContactsOfType('email', true)">
          {{'general.buttons.select_all' | translate}}
        </button>
        <button mat-flat-button color="primary" class="ml-1" (click)="selectContactsOfType('email', false)">
          {{'general.buttons.select_none' | translate}}
        </button>
      </div>
      <div *ngFor="let contact of emailContacts" class="item">
        <div class="d-flex justify-content-between align-content-end">
          <div class="details-row mt-1">
            <div class="item-name text" [matTooltip]="contact.name">{{contact.name}}
            </div>
            <div class="text light" [matTooltip]="contact.contact_function?.label">{{contact.contact_function?.label}}
            </div>
          </div>
          <div class="item-icons">
            <mat-icon class="favorite-star" (click)="updateFavoriteContact($event, contact.id, 'email')">
              {{contact.is_favorite? 'star' : 'star_outline'}}
            </mat-icon>
            <mat-checkbox color="primary" [(ngModel)]="contact.selected"></mat-checkbox>
          </div>
        </div>
      </div>
      <div class="text-center mt-3">
        <button mat-flat-button color="primary" (click)="emailLink.click()"
          [disabled]="getSelectedContactsOfTypeCount('email') < 1">
          {{'incident.contacts_list.send_by_email' | translate: {count: getSelectedContactsOfTypeCount('email')} }}
        </button>
        <a target="_blank" [href]="getLinkForContactsOfType('email')" class="d-none" #emailLink
          (click)="updateClickToCallContacts('email', $event)"></a>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
