<nav class="desktop">
  <ul>
    <ng-container *ngFor="let route of getRoutes()">
      <ng-container *ngIf="displayRouteItemForUser(route)">
        <li (click)="close()" class="pointer" routerLinkActive="active" [routerLink]="getRouterLink(route.path)">
          <ng-container *ngIf="route.data?.icon">
            <mat-icon class="mr-2">{{route.data.icon}}</mat-icon>
          </ng-container>
          <span class="nav-text">{{'navigation.' +route.data?.title | translate}}</span>
        </li>
      </ng-container>
    </ng-container>
  </ul>
  <div class="spacer"></div>
  <div class="d-flex flex-column">
    <div class="mb-5">
      <ul>
        <li class="pointer" (click)="openDoc('rcu')">
          <mat-icon class="mr-2">description</mat-icon>
          <span class="nav-text">{{'navigation.rcu' | translate}}</span>
        </li>
        <li class="pointer" (click)="openDoc('document')" *ngIf="!isVisitorOrVisitorDcmUser || !hasRole('car')">
          <mat-icon class="mr-2">description</mat-icon>
          <span class="nav-text">{{'navigation.doc' | translate}}</span>
        </li>
        <li class="pointer" (click)="openDoc('planning')" *ngIf="!isVisitorOrVisitorDcmUser">
          <mat-icon class="mr-2">description</mat-icon>
          <span class="nav-text">{{'navigation.planning' | translate}}</span>
        </li>
      </ul>
    </div>
    <div class="mx-3">
      <mat-form-field *ngIf="isAdminUser">
        <mat-label>{{'navigation.admin_role' | translate}}</mat-label>
        <mat-select name="adminRoles" [(ngModel)]="adminRole" (selectionChange)="updateAdminRole()">
          <mat-option value="dcm">DCM</mat-option>
          <mat-option value="com">COM</mat-option>
          <mat-option value="car">CAR</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</nav>

<nav class="mobile">
  <ul>
    <ng-container *ngFor="let route of getRoutes()">
      <ng-container *ngIf="displayRouteItemForUser(route)">
        <li (click)="close()" class="pointer" routerLinkActive="active" [routerLink]="getRouterLink(route.path)">
          <ng-container *ngIf="route.data?.icon">
            <mat-icon class="mr-2">{{route.data.icon}}</mat-icon>
          </ng-container>
          <span class="nav-text">{{'navigation.' +route.data?.title | translate}}</span>
        </li>
      </ng-container>
    </ng-container>
  </ul>
  <div class="spacer"></div>
  <div class="d-flex flex-column">
    <div class="mb-5">
      <ul>
        <li class="pointer" (click)="openDoc('rcu')">
          <mat-icon class="mr-2">description</mat-icon>
          <span class="nav-text">{{'navigation.rcu' | translate}}</span>
        </li>
        <li class="pointer" (click)="openDoc('document')" *ngIf="!isVisitorOrVisitorDcmUser">
          <mat-icon class="mr-2">description</mat-icon>
          <span class="nav-text">{{'navigation.doc' | translate}}</span>
        </li>
        <li class="pointer" (click)="openDoc('planning')" *ngIf="!isVisitorOrVisitorDcmUser">
          <mat-icon class="mr-2">description</mat-icon>
          <span class="nav-text">{{'navigation.planning' | translate}}</span>
        </li>
      </ul>
    </div>
    <div class="mx-3">
      <mat-form-field *ngIf="isAdminUser">
        <mat-label>{{'navigation.admin_role' | translate}}</mat-label>
        <mat-select name="adminRoles" [(ngModel)]="adminRole" (selectionChange)="updateAdminRole()">
          <mat-option value="dcm">DCM</mat-option>
          <mat-option value="com">COM</mat-option>
          <mat-option value="car">CAR</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</nav>
