import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DatetimeHelperService {

  constructor() { }

  getFormattedDateFromFullDate(fullDateString: string): string {
    fullDateString = fullDateString.replace('T', ' ').split('.')[0];
    const dateString = fullDateString.split(' ')[0];
    const timeString = fullDateString.split(' ')[1];
    return this.getFormattedDateStringFromISOString(dateString, timeString);
  }

  getFormattedDateStringFromISOString(dateString: string, timeString?: string): string {
    if (!timeString) {
      timeString = '00:00';
    } else {
      dateString = dateString.split('T')[0];
    }

    return moment(dateString + " " + timeString).format('DD/MM/YYYY HH:mm');
  }

  getFormattedDate(date: Date): string {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  getLocaleDateString(date?: Date): string {
    return moment(date).toLocaleString();
  }

  getIsoDateString(date?: Date): string {
    const momentDate = moment(date);

    // comensate non-UTC from datepicker
    momentDate.add(momentDate.utcOffset(), 'minute');

    return momentDate.toISOString();
  }

  getCurrentDateMoment(): moment.Moment {
    return moment();
  }
}
