import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { endpoints } from 'src/environments/endpoints';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ClickToCallService {

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public add(
    stationId: number, channel: string, incidentId?: number, contactIds?: number[], providerId?: number,
    fromOperatorTab?: { is_operator: boolean, is_manager_1: boolean, is_manager_2: boolean }
  ): Observable<any> {
    let body = {};
    body = { station_id: stationId, channel };

    if (incidentId) { body = { ...body, incident_id: incidentId }; }
    if (contactIds) { body = { ...body, contact_ids: contactIds }; }
    if (providerId) { body = { ...body, provider_id: providerId }; }
    if (fromOperatorTab) { body = { ...body, is_operator: fromOperatorTab.is_operator }; }
    if (fromOperatorTab) { body = { ...body, is_manager_1: fromOperatorTab.is_manager_1 }; }
    if (fromOperatorTab) { body = { ...body, is_manager_2: fromOperatorTab.is_manager_2 }; }

    return this.http.post(
      `${environment.api.host}${endpoints.clicktocall}`, body
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }
}
