<mat-sidenav-container class="h-100">
  <mat-sidenav #nav mode="over" *ngIf="userService.getUser() | async">
    <app-navigation></app-navigation>
  </mat-sidenav>
  <mat-sidenav-content class="d-flex flex-column">
    <app-header (menuClicked)="toggleNav()"></app-header>
    <main>
      <router-outlet></router-outlet>
    </main>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
