<footer *ngIf="userService.getUser() | async">
  <div class="hr-bar"></div>
  <div class="contact-numbers w-100">
    <ng-container *ngFor="let actor of actors">
      <a class="phone" target="_blank" [href]="'tel:'+actor.phone" *ngIf="isActorDisplayed(actor.code)">
        <mat-icon class="mr-1">phone</mat-icon> {{'footer.' + actor.code | translate}}
      </a>
    </ng-container>
  </div>
</footer>
