import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Contact } from 'src/app/models/contact.model';
import { Provider } from 'src/app/models/provider.model';
import { endpoints } from 'src/environments/endpoints';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  getAll(): Observable<Provider[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.providers}`
    ).pipe(
      map(
        (res: any) => res.data.providers.map(provider => new Provider(provider))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }
}
