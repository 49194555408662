import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '@ff/loader';
import { Contact } from 'src/app/models/contact.model';
import { Provider } from 'src/app/models/provider.model';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { Station } from './../../../../models/station.model';
import { absoluteRoutesNames } from './../../../shared/absolute.routes';
import { ClickToCallService } from './../../../shared/services/click-to-call.service';
import { RoleService } from './../../../shared/services/role.service';
import { StationService } from './../../services/station.service';
import { TranslateService } from '@ngx-translate/core';
import { IncidentService } from '../../services/incident.service';

@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.scss']
})
export class StationComponent implements OnInit {
  station: Station;
  ctcs: any[] = [];
  showIncidents = false;

  constructor(
    private route: ActivatedRoute,
    private loader: LoaderService,
    private stationService: StationService,
    private clickToCallService: ClickToCallService,
    private router: Router,
    private sharedService: SharedService,
    private translate: TranslateService,
    public roleService: RoleService,
    private _incidentService: IncidentService
  ) { }

  ngOnInit(): void {
    this.initData();
  }

  hideIncidents(): void {
    this.ctcs = [];
    this.showIncidents = false;
  }

  declareIncident(): void {
    this.router.navigate([absoluteRoutesNames.INCIDENT_CARD, this.station.id]);
  }

  declareCarIncident(): void {
    this.router.navigate([absoluteRoutesNames.CAR_INCIDENT, this.station.id]);
  }

  updateFavoriteStation(): void {
    this.stationService.updateFavorite(this.station.id).subscribe(
      (isFavorite: boolean) => {
        this.station.is_favorite = isFavorite;
      },
      (error) => {
      }
    );
  }

  isCarUser(): boolean {
    if (this.roleService.isAdmin()) {
      const adminRole = this.sharedService.getUserRole();
      return adminRole.toLocaleLowerCase() === 'car';
    }

    return this.roleService.isCar();
  }

  isVisitor(): boolean {
    return this.roleService.isVisitor();
  }

  isVisitorDCM(): boolean {
    return this.roleService.isVisitorDCM();
  }

  isVisitorOrVisitorDCM(): boolean {
    return this.roleService.isVisitorDCM() || this.roleService.isVisitor();
  }

  clickToCall(value: { channel: string, contactId: number }, isProvider: boolean): void {
    const contactIds = isProvider ? null : [value.contactId];
    const providerId = isProvider ? value.contactId : null;

    this.clickToCallService.add(this.station.id, value.channel, null, contactIds, providerId).subscribe(() => {
      // Nothing to do here
    });
  }

  getProviderListForCurrentRole(): Provider[] {
    const userRole = this.sharedService.getUserRole();
    if (userRole.toLocaleLowerCase() === 'car' || userRole.toLocaleLowerCase() === 'admin' || userRole.toLocaleLowerCase() === 'visitordcm') {
      return this.station.providers;
    }

    let filteredProviders = this.station.providers.filter(provider =>
      !provider.role || provider.role.toLowerCase() === userRole
    );

    filteredProviders = filteredProviders.sort((pa, pb) => {
      let paComp = this.translate.instant('entities.provider_types.' + pa.provider_type.code) ?? '';
      let pbComp = this.translate.instant('entities.provider_types.' + pb.provider_type.code) ?? '';
      return paComp.toLocaleLowerCase().localeCompare(pbComp.toLocaleLowerCase());
    });

    return filteredProviders;
  }

  getIncidents(): void {
    this.loader.show();
    this._incidentService.stationIncidents({station_code: this.station.code_implant}).subscribe({
      next: (ctcs) => {
          this.ctcs = ctcs.map((ctc) => ({
            ...ctc,
            selected: true,
          }));
          this.ctcs = this.ctcs.filter(ctc=> (ctc?.comment && ctc.comment !== '' &&  ctc.comment !== null));
          this.loader.hide();
          this.showIncidents = true;
      },
      error: (err) => {this.loader.hide();},
      complete: () => {this.loader.hide();}
    })
  }

  private initData(): void {
    this.route.params.subscribe(params => {
      const stationId = +params.id;

      this.loader.show();
      this.stationService.get(stationId).subscribe(
        (station: Station) => {
          this.station = station;
          this.orderStationContacts();
        },
        (error) => { this.loader.hide(); },
        () => { this.loader.hide(); }
      );
    });
  }

  private orderStationContacts(): void {
    this.station.contacts.sort((cA: Contact, cB: Contact) => cA.order - cB.order);
  }
}
