import { Component, Host, OnDestroy, OnInit, Optional, AfterViewInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Route } from '@angular/router';
import { LoaderService } from '@ff/loader';
import { UserService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';
import { FileSaverService } from 'ngx-filesaver';
import { Subject } from 'rxjs';
import { ConfigurationService } from 'src/app/modules/shared/services/configuration.service';
import { mainRoutes } from './../../../main/main.routes';
import { mainRoutesNames } from './../../../main/main.routes.names';
import { absoluteRoutesNames } from './../../../shared/absolute.routes';
import { SharedService } from './../../../shared/services/shared.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {

    private readonly routes: Route[] = [];
    private alive: Subject<any>;

    defaultAdminRole = 'dcm';
    adminRole: string;
    isAdminUser: boolean;
    isVisitorOrVisitorDcmUser: boolean;

    constructor(
        @Host() @Optional() private sidenav: MatSidenav,
        private sharedService: SharedService,
        private userService: UserService,
        private configurationService: ConfigurationService,
        private loader: LoaderService,
        private translate: TranslateService,
        private snackBar: MatSnackBar,
        private fileSaver: FileSaverService,
    ) {
        this.alive = new Subject();
        this.routes = mainRoutes[0].children;

        this.isAdminUser = this.userService.getUser().getValue().role.code.toLowerCase() === 'admin';
        this.isVisitorOrVisitorDcmUser = this.userService.getUser().getValue().role.code.toLowerCase() === 'visitor' || 
                                            this.userService.getUser().getValue().role.code.toLowerCase() === 'visitordcm';
    }

    hasRole(role: string): boolean {
        return this.sharedService.getUserRole() === 'car';
    }

    getRoutes(): Route[] {
        return this.routes;
    }

    displayRouteItemForUser(route: Route): boolean {
        const hasTitle = route.data?.title ? true : false;
        const isDisplayedForVisitor = route.data?.visitorAccess ? true : false;

        if (!hasTitle) {
            return false;
        }

        if (this.isVisitorOrVisitorDcmUser) {
            return hasTitle && isDisplayedForVisitor;
        }

        if (!this.isAdminUser && !this.isVisitorOrVisitorDcmUser) {
            const hiddenRolesString = route.data?.hiddenForRoles;
            const hiddenRoles: String[] = hiddenRolesString ? hiddenRolesString.split(',') : [];

            return !hiddenRoles.includes(this.sharedService.getUserRole());
        }

        return true;
    }

    close(): void {
        if (this.sidenav) {
            this.sidenav.close();
        }
    }

    updateAdminRole(): void {
        this.sharedService.setAdminRole(this.adminRole);
        window.location.reload();
    }

    ngOnInit(): void {
        if (!this.isAdminUser) { return; }

        let adminRole = this.sharedService.getUserRole();
        if (!adminRole || adminRole === '') {
            this.sharedService.setAdminRole(this.defaultAdminRole);
            adminRole = this.defaultAdminRole;
        }
        this.adminRole = adminRole;
    }

    ngOnDestroy(): void {
        this.alive.next();
        this.alive.complete();
    }

    getRouterLink(routePath: string): string {
        const keyName = Object.keys(mainRoutesNames).find(key => mainRoutesNames[key] === routePath);

        if (keyName) {
            return absoluteRoutesNames[keyName];
        }

        return routePath;
    }

    openDoc(docName: string): void {
        this.loader.show();
        this.configurationService.getDocumentFile(docName).subscribe(
            (blob: Blob) => {
                const ext = docName === 'planning' ? 'xlsx' : 'pdf';
                const type = docName === 'planning' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf';
                this.fileSaver.save(blob, `${docName}.${ext}`, `${type}`);
                this.loader.hide();
            },
            (err) => {
                this.loader.hide();
                this.snackBar.open(this.translate.instant('general.messages.errors.generic'));
            },
            () => { this.loader.hide(); }
        );
    }
}
