
// tslint:disable:variable-name
export class SeverityLevel {

  id: number;
  level: number;
  code: string;
  hex_color: string;
  pivot?: { incident_type_id: number, severity_level_id: number, description: string; }

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
