
export class Configuration {

  car: string;
  com: string;
  dcm: string;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
