import { endpoints } from './../../../../environments/endpoints';
import { environment } from './../../../../environments/environment';
import { ErrorHandlerService } from './error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { User } from './../../../models/user.model';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  isCar(): boolean {
    return this.isRole('CAR');
  }

  isDcm(): boolean {
    return this.isRole('DCM');
  }

  isCom(): boolean {
    return this.isRole('COM');
  }

  isAdmin(): boolean {
    return this.isRole('ADMIN');
  }

  isVisitor(): boolean  {
    return this.isRole('VISITOR');
  }

  isVisitorDCM(): boolean  {
    return this.isRole('VISITORDCM');
  }

  isRole(roleName: 'CAR' | 'COM' | 'DCM' | 'ADMIN' | 'VISITOR' | 'VISITORDCM'): boolean {
    const user = this.getUser();
    if (!user) { return false; }

    return user.role.code === roleName;
  }

  getDcmUsers(): Observable<User[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.dcm_users}`
    ).pipe(
      map(
        (res: any) => res.data.users_of_type.map(user => new User(user))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getComUsers(): Observable<User[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.com_users}`
    ).pipe(
      map(
        (res: any) => res.data.users_of_type.map(user => new User(user))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  public getUser(): User {
    const isLoggedIn = this.authService.isLoggedIn().getValue();

    if (!isLoggedIn) { return null; }

    const user = new User(this.userService.getUser().getValue());

    return user;
  }

  public getAllUsers(): Observable<User[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.users}`
    ).pipe(
      map(
        (res: any) => res.data.users.map(user => new User(user))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }
}
