import { Routes } from '@angular/router';
import { GuestGuard, LoggedInGuard } from '@ff/core';
import { coreRoutesNames } from '../core/core.routes.names';
import { authRoutesNames } from './auth.routes.names';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';

export const routes: Routes = [
  {
    path: coreRoutesNames.AUTH,
    children: [
      { path: authRoutesNames.LOGIN, component: LoginComponent, canActivate: [GuestGuard] },
      { path: authRoutesNames.LOGOUT, component: LogoutComponent, canActivate: [LoggedInGuard] },
    ]
  },
  {
    path: '',
    component: LoginComponent
  }
];
