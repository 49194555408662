import { Station } from './station.model';

// tslint:disable:variable-name
export class CarIncident {

  id: number;
  station: Station;
  car_id: number;
  dcm_id: number;
  com_id: number;
  description: string;
  actions_taken: string;
  has_com_read: boolean;
  has_dcm_read: boolean;
  is_com_done: boolean;
  is_dcm_done: boolean;
  created_at: string;
  updated_at: string;

  // Only for UI purposes => for export
  selected?: boolean;

  constructor(data = {}) {
    Object.assign(this, data);
    this.station = new Station(this.station);
  }
}

