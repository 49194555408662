import { Component, OnInit } from '@angular/core';
import { UserService } from '@ff/user';
import { Configuration } from 'src/app/models/configuration.model';
import { ConfigurationService } from 'src/app/modules/shared/services/configuration.service';
import { RoleService } from 'src/app/modules/shared/services/role.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  actors: { code: string, phone: string }[] = [
    { code: 'dcm', phone: environment.phones?.dcm ?? '' },
    { code: 'car', phone: environment.phones?.car ?? '' },
    { code: 'com', phone: environment.phones?.com ?? '' },
  ];

  constructor(
    private configurationService: ConfigurationService,
    private roleService: RoleService,
    public userService: UserService,
  ) { }

  ngOnInit(): void {
    this.configurationService.get().subscribe((conf: Configuration) => {
      this.actors.forEach(actor => {
        if (conf[actor.code]) {
          actor.phone = conf[actor.code];
        }
      });
    });
  }

  callPhone(actor: 'dcm' | 'com' | 'car'): void {
    window.open(`tel:${environment.phones[actor]}`, '_blank');
  }

  isActorDisplayed(actorCode: string): boolean {

    const user = this.userService.getUser().getValue();
    if (!user) {
      // || user.role.code === 'admin') {
      return false;
    }

    if (user.role.code.toLowerCase() === actorCode) {
      return false;
    }

    if ((this.roleService.isVisitor() || this.roleService.isVisitorDCM())&& actorCode !== 'car') {
      return false;
    }

    return true;
  }
}
