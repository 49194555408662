import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { absoluteRoutesNames } from 'src/app/modules/shared/absolute.routes';

@Component({
  selector: 'app-stations-list',
  templateUrl: './stations-list.component.html',
  styleUrls: ['./stations-list.component.scss']
})
export class StationsListComponent implements OnInit {

  @Input() stations;
  @Input() limit = 25;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  gotoStation(stationId: number): void {
    this.router.navigate([absoluteRoutesNames.STATION, stationId]);
  }
}
