import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Contact } from 'src/app/models/contact.model';

@Component({
  selector: 'app-contact-bottom-sheet',
  templateUrl: './contact-bottom-sheet.component.html',
  styleUrls: ['./contact-bottom-sheet.component.scss']
})
export class ContactBottomSheetComponent implements OnInit {

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ContactBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public contactData: {
      contact: Contact, onlyTel: boolean, onlySms: boolean, onlyEmail: boolean, hideMultiEmail: boolean
    }
  ) { }

  ngOnInit(): void {
  }

  clickToCall(channel: string, contactId: number): void {
    this._bottomSheetRef.dismiss({ channel, contactId });
  }

  displayTel(): boolean {
    return !(this.contactData.onlySms || this.contactData.onlyEmail);
  }

  displaySms(): boolean {
    return !(this.contactData.onlyTel || this.contactData.onlyEmail);
  }

  displayEmail(): boolean {
    return !(this.contactData.onlySms || this.contactData.onlyTel);
  }

  displayMultiEmail(): boolean {
    return !this.contactData.hideMultiEmail && (this.contactData.contact.stations_emails?.length > 0);
  }

  getMultiMailsList(contact: Contact, separator: string) {
    return contact.stations_emails.join(separator);
  }
}
