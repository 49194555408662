<h3 class="mt-1 mb-2 text-center">
  {{title}}
</h3>
<mat-nav-list>
    <ng-container *ngIf="phone">
      <!-- TODO: Add &body=... in link -->
      <a mat-list-item target="_blank" [href]="'sms:'+phone" *ngIf="isMobilePhone(phone)"
        (click)="clickToCall('sms')">
        <div mat-line>
          <mat-icon color="primary" class="mr-3">sms</mat-icon>
          <div>
            <div>{{'general.contact_methods.sms' | translate}}</div>
            <div class="text light">{{phone}}</div>
          </div>
        </div>
      </a>
      <a mat-list-item target="_blank" [href]="'tel:'+phone"
        (click)="clickToCall(isMobilePhone(phone) ? 'cellphone' : 'phone')">
        <div mat-line>
          <mat-icon color="primary" class="mr-3">{{isMobilePhone(phone) ? 'smartphone' : 'phone'}}
          </mat-icon>
          <div>
            <div>{{'general.contact_methods.phone' | translate}}</div>
            <div class="text light">{{phone}}</div>
          </div>
        </div>
      </a>
    </ng-container>
</mat-nav-list>
