import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Provider } from 'src/app/models/provider.model';

@Component({
  selector: 'app-provider-bottom-sheet',
  templateUrl: './provider-bottom-sheet.component.html',
  styleUrls: ['./provider-bottom-sheet.component.scss']
})
export class ProviderBottomSheetComponent implements OnInit {

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ProviderBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public providerData: { provider: Provider }
  ) { }

  ngOnInit(): void {
  }

  clickToCall(channel: string, providerId: number): void {
    this._bottomSheetRef.dismiss({ channel, providerId });
  }

  isMobilePhone(phone: string): boolean {
    return phone.startsWith('06') || phone.startsWith('07');
  }
}
