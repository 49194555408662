import { Contact } from 'src/app/models/contact.model';

// tslint:disable:variable-name
export class SelectableContact extends Contact {

  selected = true;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }
}
