export const environment = {
  production: true,
  api: {
    host: 'https://api-astreinte-reseau.mpsprod.alzp.tgscloud.net'
  },
  phones: {
    car: '0980090000',
    com: '0243594307',
    dcm: '0243594308'
  }
};
