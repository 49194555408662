import { BasicCodedObject } from './basic-coded-object.model';
import { BasicLabelledObject } from './basic-labelled-object.model';
import { Contact } from './contact.model';
import { Provider } from './provider.model';
import { StationInfo } from './station-info.model';

// tslint:disable:variable-name
export class Station {

  id: number;
  code_implant: string;
  status: BasicCodedObject;
  info: StationInfo;
  specifics: BasicLabelledObject[];
  providers: Provider[];
  contacts: Contact[];
  is_favorite: boolean;

  constructor(data = {}) {
    Object.assign(this, data);
    this.status = new BasicCodedObject(this.status);
    this.info = new StationInfo(this.info);
    this.specifics = this.specifics?.map(specific => new BasicLabelledObject(specific));
    this.providers = this.providers?.map(provider => new Provider(provider));
    this.contacts = this.contacts?.map(contact => new Contact(contact));
  }
}
