<div class="main-content">
  <div class="text-center">
    <h2 class="pb-0">{{ station?.info.site_name}}</h2>
    <h3 class="pt-0">{{station?.code_implant}}</h3>
  </div>

  <mat-vertical-stepper [linear]="true" #stepper (selectionChange)="stepperSelectionChange($event)">
    <mat-step [stepControl]="incidentTypesFormGroup" [editable]="isEditable" name="incident-step-1">
      <form [formGroup]="incidentTypesFormGroup">
        <ng-template matStepLabel>
          {{'incident.incident_type' | translate}}
          <ng-container *ngIf="step1IsValid()">
            <br><small>{{getCurrentIncidentTypeLabel()}}</small>
          </ng-container>
        </ng-template>
        <mat-radio-group color="primary" formControlName="incident_type_id" required>
          <div *ngFor="let incidentType of incidentTypes" class="custom-radio-container">
            <mat-radio-button [value]="incidentType.id" class="d-flex align-items-center justify-content-between">
              <span class="description">{{incidentType.description}}</span>
            </mat-radio-button>
            <mat-icon (click)="openReflexSheetDialog($event, incidentType.reflex_sheet)" class="info">info</mat-icon>
          </div>
        </mat-radio-group>
        <div class="mt-3 d-flex justify-content-end">
          <button mat-flat-button color="primary" matStepperNext>{{'general.buttons.next' | translate}}</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="severityLevelFormGroup" [editable]="isEditable" name="incident-step-2">
      <form [formGroup]="severityLevelFormGroup">
        <ng-template matStepLabel>
          {{'incident.incident_severity' | translate}}
          <ng-container *ngIf="step2IsValid()">
            <br><small [ngStyle]="{'color': getCurrentSeverityLevel().hex_color}">
              {{'entities.severity_levels.' + getCurrentSeverityLevel().code | translate}}
            </small>
          </ng-container>
        </ng-template>
        <mat-radio-group color="primary" formControlName="severity_level_id" required>
          <div class="custom-radio-container" *ngFor="let severityLevel of severityLevels">
            <mat-radio-button [value]="severityLevel.id">
              <span [ngStyle]="{'color': severityLevel.hex_color}">
                {{'entities.severity_levels.' + severityLevel.code | translate}}</span>
              <span class="description"> : {{severityLevel.pivot.description}}</span>
            </mat-radio-button>
          </div>
        </mat-radio-group>

        <div class="mt-3 d-flex justify-content-between">
          <button mat-flat-button matStepperPrevious>{{'general.buttons.previous' | translate}}</button>
          <button mat-flat-button color="primary" matStepperNext>{{'general.buttons.next' | translate}}</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="detailsFormGroup" [editable]="false" name="incident-step-3">
      <form [formGroup]="detailsFormGroup">
        <ng-template matStepLabel>{{'incident.details' | translate}}</ng-template>
        <mat-form-field class="disabled-input-datepicker" (click)="incidentDatePicker.open()">
          <mat-label>{{'incident.declaration_date' | translate}}</mat-label>
          <input matInput formControlName="declaration_date" [matDatepicker]="incidentDatePicker" [max]="maxDate"
            readonly required>
          <mat-datepicker-toggle matSuffix [for]="incidentDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #incidentDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="disabled-input-datepicker">
          <mat-label>{{'incident.declaration_time' | translate}}</mat-label>
          <input matInput formControlName="declaration_time" [ngxMatTimepicker]="incidentTimePicker" format="24"
            placeholder="00:00" readonly required />
          <mat-icon class="mr-1" matSuffix (click)="incidentTimePicker.open()" color="accent">schedule</mat-icon>
        </mat-form-field>
        <ngx-mat-timepicker #incidentTimePicker></ngx-mat-timepicker>
        <mat-form-field>
          <mat-label>{{'incident.comment' | translate}}</mat-label>
          <textarea matInput formControlName="comment" rows="10"
            [placeholder]="'general.messages.free_input_warning' | translate"></textarea>
          <mat-hint>{{'incident.comment_hint' | translate: {number: getCommentLength()} }}</mat-hint>
        </mat-form-field>
        <div class="mt-3 d-flex justify-content-between">
          <button mat-flat-button matStepperPrevious>{{'general.buttons.previous' | translate}}</button>
          <button mat-flat-button color="primary" matStepperNext [disabled]="!allStepsValid()">
            {{'general.buttons.next'| translate}}
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step name="incident-step-4">
      <ng-template matStepLabel>{{'incident.contacts' | translate}}</ng-template>
      <ng-container *ngIf="stepper.selectedIndex === 3">
        <div class="items-list" *ngIf="contacts.length > 0; else noContactsTemplate">
          <app-incident-contacts-list [station]="station" [contacts]="contacts"
            [incidentData]="IncidentData" [callbackCreateIncident]="submitIncident">
          </app-incident-contacts-list>
        </div>
        <ng-template #noContactsTemplate>
          <ng-container *ngIf="isLoadingContacts; else noContactsLabelTemplate">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </ng-container>
          <ng-template #noContactsLabelTemplate>
            {{ 'incident.no_contacts' | translate}}
          </ng-template>
        </ng-template>
      </ng-container>

      <div class="mt-5 d-flex justify-content-center">
        <button mat-flat-button matStepperPrevious color="primary" (click)="closeIncident()">
          {{'general.buttons.close' | translate}}
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
