import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '@ff/loader';
import { TranslateService } from '@ngx-translate/core';
import { Contact } from 'src/app/models/contact.model';
import { Incident, IncidentInterface } from 'src/app/models/incident.model';
import { IncidentType } from './../../../../models/incident-type.model';
import { SeverityLevel } from './../../../../models/severity-level.model';
import { Station } from './../../../../models/station.model';
import { DatetimeHelperService } from './../../../shared/services/datetime-helper.service';
import { SharedService } from './../../../shared/services/shared.service';
import { IncidentService } from './../../services/incident.service';
import { StationService } from './../../services/station.service';
import { ReflexSheetDialogComponent } from './dialogs/reflex-sheet-dialog/reflex-sheet-dialog.component';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-incident-card',
  templateUrl: './incident-card.component.html',
  styleUrls: ['./incident-card.component.scss']
})
export class IncidentCardComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;

  station: Station;
  incidentId: number;
  incidentTypes: IncidentType[] = [];
  severityLevels: SeverityLevel[] = [];
  contacts: Contact[] = [];

  incidentTypesFormGroup: FormGroup;
  severityLevelFormGroup: FormGroup;
  detailsFormGroup: FormGroup;

  maxDate: Date;

  isEditable = true;
  isLoadingContacts = false;

  incidentData:any;

  constructor(
    private fb: FormBuilder,
    private loader: LoaderService,
    private stationService: StationService,
    private incidentService: IncidentService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private location: Location,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private datetimeHelper: DatetimeHelperService,
  ) { }

  ngOnInit(): void {
    this.maxDate = new Date();
    this.initData();
    this.initForms();
  }

  openReflexSheetDialog(event: Event, reflexSheetText: string): void {
    event.stopPropagation();

    this.dialog.open(ReflexSheetDialogComponent, {
      width: 'calc(100vw - 2rem)',
      height: 'calc(100vh - 2rem)',
      maxWidth: 'calc(100vw - 2rem)',
      maxHeight: 'calc(100vh - 2rem)',
      data: { reflexSheetText }
    });
  }

  stepperSelectionChange(event: StepperSelectionEvent): void {
    if (event.selectedIndex === 0) {
      this.severityLevels = [];
      return;
    }

    if (event.selectedIndex === 1) {
      const incidentTypeId = this.incidentTypesFormGroup.get('incident_type_id').value;
      this.incidentService.getTypeWithSeverityLevels(incidentTypeId).subscribe((incidentTypeWithSeverityLevels: IncidentType) => {
        this.severityLevels = incidentTypeWithSeverityLevels.severity_levels;
      });
    }

    if (event.selectedIndex === 3) {
      this.createIncident();
    }
  }

  getCurrentIncidentTypeLabel(): string {
    const currentType = this.incidentTypes.find(incType => incType.id === this.incidentTypesFormGroup.get('incident_type_id').value);
    return currentType.description;
  }

  getCurrentSeverityLevel(): SeverityLevel {
    const currentSeverityLevel = this.severityLevels.find(sl => sl.id === this.severityLevelFormGroup.get('severity_level_id').value);
    return currentSeverityLevel;
  }

  getCommentLength(): number {
    const commentValue = this.detailsFormGroup.get('comment').value;
    return (commentValue ? commentValue.length : 0);
  }

  step1IsValid(): boolean {
    return this.incidentTypesFormGroup.valid && (this.stepper.selectedIndex > 0);
  }

  step2IsValid(): boolean {
    return this.severityLevelFormGroup.valid && (this.stepper.selectedIndex > 1);
  }

  step3IsValid(): boolean {
    return this.detailsFormGroup.valid && (this.stepper.selectedIndex > 1);
  }

  allStepsValid(): boolean {
    return this.step1IsValid() && this.step2IsValid() && this.step3IsValid();
  }

  createIncident(): void {
    if (!(this.incidentTypesFormGroup.valid && this.severityLevelFormGroup.valid)) {
      return;
    }

    this.incidentData = this.getIncidentData();

    if (!this.loader.isVisible()) {
      this.loader.show();
    }

    const matriceId = this.incidentTypesFormGroup.value.incident_type_id;
        const severityLevelId = this.severityLevelFormGroup.value.severity_level_id;

        this.isEditable = false;
        this.isLoadingContacts = true;
        this.incidentService.getMatriceLevelContacts(this.station.id, matriceId, severityLevelId).subscribe((contacts: Contact[]) => {
          // No filtering for incident card, only for contacts list in station card
          this.contacts = contacts;

          this.isLoadingContacts = false;
        },
          (error) => {
            this.loader.hide();
            this.isLoadingContacts = false;
          }, () => {
            this.loader.hide();
            this.isLoadingContacts = false;
          });
    
  }

  submitIncident(args:string, incidentService, snackBar): Observable<number>{
    var subject = new Subject<number>();
    let tempIncidentId;
   if (args == "click") {
    const httpCall = this.incidentId ? incidentService.update(this.incidentData, this.incidentId) : incidentService.create(this.incidentData);
    httpCall.subscribe(
      (incident: Incident) => {
        this.incidentId = incident.id;
        this.incidentData = incident;
        tempIncidentId = incident.id;
        snackBar.open(this.translate.instant('general.messages.data_saved'));
        subject.next(tempIncidentId);
      },
      (error) => {
        this.loader.hide();
        this.isLoadingContacts = false;
      }
    );

    return subject.asObservable();
   }
  }

  closeIncident(): void {
    this.location.back();
  }

  get IncidentData(): IncidentInterface {
    return this.getIncidentDataForContact()
  }

  getIncidentDataForContact(): IncidentInterface {
    const incidentTypeLabel = this.getCurrentIncidentTypeLabel();
    const currentSeverityLevel = this.getCurrentSeverityLevel();
    const severityLevelLabel = this.translate.instant('entities.severity_levels.' + currentSeverityLevel.code);
    const severityLevelDescription = currentSeverityLevel.pivot.description;

    return {
      ...this.getIncidentData(),
      incidentTypeLabel,
      severityLevelLabel,
      severityLevelDescription
    }
  }

  private getIncidentData(): any {
    return {
      id: this.incidentId,
      station_id: this.station.id,
      ...this.incidentTypesFormGroup.value,
      ...this.severityLevelFormGroup.value,
      ...this.detailsFormGroup.value,
      declaration_date: this.datetimeHelper.getIsoDateString(this.detailsFormGroup.value.declaration_date),
      declaration_time: this.detailsFormGroup.value.declaration_time,
    };
  }

  private initData(): void {
    this.route.params.subscribe(params => {
      const stationId = +params.station_id;

      this.loader.show();

      const incidentTypeForUser = this.sharedService.getUserRole();
      this.incidentService.getAllTypes(incidentTypeForUser).subscribe((incidentTypes: IncidentType[]) => {
        this.incidentTypes = incidentTypes;

        this.incidentTypes.sort((a, b) => {
          return a.description.localeCompare(b.description);
        });
      });

      this.stationService.get(stationId).subscribe(
        (station: Station) => {
          this.station = station;
        },
        (error) => { this.loader.hide(); },
        () => { this.loader.hide(); }
      );
    });
  }

  private initForms(): void {
    const now = new Date();
    const nowTimeString = now.toTimeString().split(' ')[0].slice(0, -3);

    this.incidentTypesFormGroup = this.fb.group({
      incident_type_id: [null, Validators.required]
    });
    this.severityLevelFormGroup = this.fb.group({
      severity_level_id: [null, Validators.required]
    });
    this.detailsFormGroup = this.fb.group({
      declaration_date: [now, Validators.required],
      declaration_time: [nowTimeString, Validators.required],
      comment: [null, Validators.maxLength(1000)]
    });
  }
}

