<div class="main-content">
  <h1>{{'navigation.grouped_contact' | translate}}</h1>

  <form [formGroup]="searchStationsForm" (ngSubmit)="searchStations()" novalidate>
    <mat-card class="mb-3">
      <mat-form-field class="mb-2">
        <mat-label>{{'grouped_contact.search.sales_channel' | translate}}</mat-label>
        <input matInput formControlName="sales_channel" name="sales-channel">
        <mat-icon color="primary" matSuffix *ngIf="searchStationsForm.get('sales_channel').value?.trim()"
          (click)="clearSearch('sales_channel')" class="pointer">clear</mat-icon>
        <mat-hint>{{'grouped_contact.search.sales_channel_hint' | translate}}</mat-hint>
      </mat-form-field>
      <mat-form-field class="mb-2">
        <mat-label>{{'grouped_contact.search.zip_or_department' | translate}}</mat-label>
        <input matInput formControlName="zip_or_department" name="zip-or-department">
        <mat-icon color="primary" matSuffix *ngIf="searchStationsForm.get('zip_or_department').value?.trim()"
          (click)="clearSearch('zip_or_department')" class="pointer">clear</mat-icon>
        <mat-hint>{{'grouped_contact.search.zip_or_department_hint' | translate}}</mat-hint>
      </mat-form-field>
      <mat-form-field class="mb-2">
        <mat-label>{{'grouped_contact.search.city' | translate}}</mat-label>
        <input matInput formControlName="city" name="city">
        <mat-icon color="primary" matSuffix *ngIf="searchStationsForm.get('city').value?.trim()"
          (click)="clearSearch('city')" class="pointer">clear</mat-icon>
        <mat-hint>{{'grouped_contact.search.city_hint' | translate}}</mat-hint>
      </mat-form-field>
    </mat-card>
  </form>

  <mat-spinner class="mx-auto my-3" *ngIf="isLoadingResults; else resultsListTemplate"></mat-spinner>
  <ng-template #resultsListTemplate>
    <ng-container *ngIf="searchStationsResults">
      <app-stations-list [stations]="searchStationsResults"></app-stations-list>
    </ng-container>
  </ng-template>
</div>

<div class="stations-contact" *ngIf="searchStationsResults?.length > 0 && !isLoadingResults">
  <div>{{'grouped_contact.contact_stations.title' | translate: {count: searchStationsResults?.length} }}</div>
  <div class="flex-shrink-0">
    <button mat-flat-button class="mr-1" (click)="emailLink.click()">
      {{'grouped_contact.contact_stations.by_mail' | translate }}
    </button>
    <button mat-flat-button class="ml-1" (click)="smsLink.click()">
      {{'grouped_contact.contact_stations.by_sms' | translate }}
    </button>
    <a target="_blank" [href]="getLinkForGroupedMail()" class="d-none" #emailLink></a>
    <a target="_blank" [href]="getLinkForGroupedSms()" class="d-none" #smsLink></a>
  </div>
</div>
