<div class="main-content">
  <h1>{{'navigation.contacts' | translate}}</h1>
  <form [formGroup]="searchContactForm" (ngSubmit)="searchContacts()" novalidate>
    <mat-card class="mb-3">
      <mat-form-field>
        <mat-label>{{'contacts.search.contact' | translate}}</mat-label>
        <input matInput formControlName="query" name="contacts_search">
        <mat-icon color="primary" matSuffix *ngIf="!searchContactForm.get('query').value?.trim()"
          (click)="searchContacts()" class="pointer">search</mat-icon>
        <mat-icon color="primary" matSuffix *ngIf="searchContactForm.get('query').value?.trim()" (click)="clearSearch()"
          class="pointer">clear</mat-icon>
        <mat-hint>{{'contacts.search.contact_hint' | translate}}</mat-hint>
      </mat-form-field>
    </mat-card>
  </form>

  <ng-container *ngIf="searchContactsResults">
    <h2 class="mb-3">{{'contacts.search.results.contacts' | translate}}</h2>
    <mat-card class="my-3">
      <mat-spinner class="mx-auto my-2" *ngIf="isLoadingResults; else resultsListTemplate"></mat-spinner>
      <ng-template #resultsListTemplate>
        <app-contacts-list [contacts]="searchContactsResults" [ignoreIsDisplayed]="true"></app-contacts-list>
      </ng-template>
    </mat-card>
  </ng-container>
</div>
