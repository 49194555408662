export const endpoints = {
  com_users: '/users/com',
  dcm_users: '/users/dcm',
  car_incidents: '/car-incidents',
  clicktocall: '/clicktocall',
  configuration: '/configuration',
  contact_favorites: '/contact-favorites',
  contacts: '/contacts',
  documents: '/documents',
  favorite_stations: '/favorite-stations',
  i18n: '/static/i18n',
  incident_types: '/incident-types',
  incidents: '/incidents',
  station_incidents: '/station-incidents',
  matrices: '/matrices',
  providers: '/providers',
  report: '/report',
  search: '/search',
  search_multi: '/search-multi',
  severity_levels: '/severity-levels',
  stations: '/stations',
  users: '/users'
};
