import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { absoluteRoutesNames } from 'src/app/modules/shared/absolute.routes';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '@ff/loader';
import { forkJoin } from 'rxjs';
import { Station } from './../../../../models/station.model';
import { User } from './../../../../models/user.model';
import { RoleService } from './../../../shared/services/role.service';
import { IncidentService } from './../../services/incident.service';
import { StationService } from './../../services/station.service';

@Component({
  selector: 'app-car-incident',
  templateUrl: './car-incident.component.html',
  styleUrls: ['./car-incident.component.scss']
})
export class CarIncidentComponent implements OnInit {

  station: Station;
  carIncidentForm: FormGroup;
  dcmUsers: User[];
  comUsers: User[];

  constructor(
    private fb: FormBuilder,
    private loader: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private roleService: RoleService,
    private stationService: StationService,
    private incidentService: IncidentService
  ) { }

  getInputLength(inputName: string): number {
    const inputValue = this.carIncidentForm.get(inputName).value as string;
    return inputValue ? inputValue.length : 0;
  }

  ngOnInit(): void {
    this.initData();
    this.initForm();
  }

  submitCarIncident(): void {
    if (!this.isCarIncidentFormValid()) {
      return;
    }

    const formData = {
      ...this.carIncidentForm.getRawValue(),
      station_id: this.station.id
    };

    this.incidentService.createForCar(formData).subscribe(() => {
      this.snackBar.open(this.translate.instant('general.messages.data_saved'));
      this.router.navigate([absoluteRoutesNames.STATION, this.station.id])
    });
  }

  isCarIncidentFormValid(): boolean {
    if (!this.carIncidentForm.valid) { return false; }

    const formValue = this.carIncidentForm.getRawValue();
    return (formValue.dcm_id || formValue.com_id);
  }

  getContactName(contact: User): string {
    return contact.last_name.toLocaleUpperCase() + ' ' + contact.first_name;
  }

  private initData(): void {
    this.route.params.subscribe(params => {
      const stationId = +params.station_id;

      this.loader.show();

      this.stationService.get(stationId).subscribe(
        (station: Station) => {
          this.station = station;
        },
        (error) => { this.loader.hide(); },
        () => { this.loader.hide(); }
      );
    });

    forkJoin([this.roleService.getComUsers(), this.roleService.getDcmUsers()])
      .subscribe(([comUsers, dcmUsers]: [User[], User[]]) => {
        this.comUsers = comUsers;
        this.dcmUsers = dcmUsers;
      });
  }

  private initForm(): void {
    this.carIncidentForm = this.fb.group({
      description: ['', [Validators.required, Validators.maxLength(500)]],
      actions_taken: ['', Validators.maxLength(500)],
      dcm_id: [''],
      com_id: ['']
    });
  }
}
