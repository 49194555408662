import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Contact } from 'src/app/models/contact.model';
import { Incident } from 'src/app/models/incident.model';
import { environment } from 'src/environments/environment';
import { endpoints } from './../../../../environments/endpoints';
import { CarIncident } from './../../../models/car-incident.model';
import { IncidentType } from './../../../models/incident-type.model';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class IncidentService {

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  search(searchInput: { user_id?: string, date_start?: string, date_end?: string, query?: string }): Observable<any[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.incidents}${endpoints.search}`, {
      params: searchInput
    }
    ).pipe(
      map(
        (res: any) => res.data.ctcs
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  searchCarIncidents(searchInput: { date_start: string, date_end: string }): Observable<CarIncident[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.car_incidents}${endpoints.search}`, {
      params: searchInput
    }
    ).pipe(
      map(
        (res: any) => res.data.car_incidents.map(carIncident => new CarIncident(carIncident))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  stationIncidents(searchInput: { station_code?: string }): Observable<any[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.station_incidents}`, {
      params: searchInput
    }
    ).pipe(
      map(
        (res: any) => res.data.ctcs
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }


  getAllTypes(type: string): Observable<IncidentType[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.incident_types}/${type}`
    ).pipe(
      map(
        (res: any) => res.data.incident_types.map(incidentType => new IncidentType(incidentType))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getTypeWithSeverityLevels(incidentTypeId: any): Observable<IncidentType> {
    return this.http.get(
      `${environment.api.host}${endpoints.incident_types}${endpoints.severity_levels}/${incidentTypeId}`
    ).pipe(
      map(
        (res: any) => new IncidentType(res.data.incident_type_with_severity_levels)
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getMatriceLevelContacts(stationId: number, incidentTypeId: number, severityLevelId: number): Observable<Contact[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.contacts}` +
      `${endpoints.stations}/${stationId}` +
      `${endpoints.incident_types}/${incidentTypeId}` +
      `${endpoints.severity_levels}/${severityLevelId}`
    ).pipe(
      map(
        (res: any) => res.data.contacts.map(contact => new Contact(contact))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  create(incidentData: any): Observable<any> {
    return this.http.post(
      `${environment.api.host}${endpoints.incidents}`, incidentData
    ).pipe(
      map(
        (res: any) => new Incident(res.data.incident)
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  createForCar(carIncidentData: any): Observable<any> {
    return this.http.post(
      `${environment.api.host}${endpoints.car_incidents}`, carIncidentData
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  generateReport(
    query: { excluded_ids: { id: number, object_type: string }[], date_start: string, date_end: string, user_id: string }
  ): Observable<any> {
    return this.http.post(
      `${environment.api.host}${endpoints.incidents}${endpoints.report}`,
      query
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  generateCarReport(query: { excluded_ids: number[], date_start: string, date_end: string }): Observable<any> {
    return this.http.post(
      `${environment.api.host}${endpoints.car_incidents}${endpoints.report}`,
      query
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  update(incidentData: any, incidentId = null): Observable<any> {
    let targetIncidentId = incidentData.id ?? incidentId;
    return this.http.put(
      `${environment.api.host}${endpoints.incidents}/${targetIncidentId}`, incidentData
    ).pipe(
      map(
        (res: any) => new Incident(res.data.incident)
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  updateIncidentContacts(incidentId: number, contactIds: number[], channel: 'phone' | 'sms' | 'email'): Observable<any> {
    return this.http.put(
      `${environment.api.host}${endpoints.incidents}${endpoints.contacts}`,
      { incident_id: incidentId, contact_ids: contactIds, channel }
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }
}
