import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Configuration } from 'src/app/models/configuration.model';
import { endpoints } from 'src/environments/endpoints';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../../shared/services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  get(): Observable<Configuration> {
    return this.http.get(
      `${environment.api.host}${endpoints.configuration}`
    ).pipe(
      map(
        (res: any) => new Configuration(res.data.configuration)
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getDocumentFile(filename: string, legal: boolean = false): Observable<any> {
    const url = legal ? environment.api.host + endpoints.documents + '/legal/' + filename : environment.api.host + endpoints.documents + '/' + filename;
    return this.http.get(url, {
      responseType: 'blob'
    });
  }

}
