import { Injectable } from '@angular/core';
import { UserService } from '@ff/user';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private readonly LS_KEY_PREFIX = 'totalenergies.mps.astreinte_reseau.';

  readonly ADMIN_ROLE_LS_KEY = `${this.LS_KEY_PREFIX}admin_role`;
  readonly SEARCHES_LS_KEY = `${this.LS_KEY_PREFIX}searches`;
  readonly SEARCH_CONTACT_LS_KEY_SUFFIX = 'contact';
  readonly SEARCH_PROVIDER_LS_KEY_SUFFIX = 'provider';
  readonly SEARCH_STATION_LS_KEY_SUFFIX = 'station';
  readonly SEARCH_GROUPED_CONTACT_LS_KEY_SUFFIX = 'grouped_contact';

  constructor(private userService: UserService) { }

  getUserRole(): string {
    const user = this.userService.getUser().getValue();

    if (user.role.code.toLowerCase() !== 'admin') {
      return user.role.code.toLowerCase();
    }

    const role = localStorage.getItem(this.ADMIN_ROLE_LS_KEY);

    return (role ? role.toLowerCase() : 'admin');
  }

  setAdminRole(chosenRole: string): void {
    localStorage.setItem(this.ADMIN_ROLE_LS_KEY, chosenRole);
  }

  getSearches(): any {
    let searches = localStorage.getItem(this.SEARCHES_LS_KEY);
    if (!searches) {
      this.createSearches();
      searches = localStorage.getItem(this.SEARCHES_LS_KEY);
    }

    return JSON.parse(searches);
  }

  setSearch(key: string, searchInput: string | any): void {
    const searches = this.getSearches();
    searches[key] = searchInput;
    localStorage.setItem(`${this.SEARCHES_LS_KEY}`, JSON.stringify(searches));
  }

  clearSearch(key: string): void {
    this.setSearch(key, '');
  }

  private createSearches(): void {
    localStorage.setItem(this.SEARCHES_LS_KEY, JSON.stringify({}));
  }
}
