<div class="top-button">
  <button mat-flat-button color="primary" [disabled]="!canCreateReport()" (click)="generateReport()">
    <mat-icon>file_download</mat-icon>{{'new_incidents.create_report' | translate}}
  </button>
</div>

<mat-card>
  <form [formGroup]="searchForm">
    <div class="search-bar">
      <mat-form-field class="disabled-input-datepicker" (click)="pickerStart.open()">
        <mat-label>{{'new_incidents.search.from'|translate}}</mat-label>
        <input matInput [matDatepicker]="pickerStart" formControlName="date_start" readonly>
        <div matSuffix class="d-flex align-items-center">
          <mat-datepicker-toggle [for]="pickerStart"></mat-datepicker-toggle>
        </div>
        <mat-datepicker #pickerStart></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="disabled-input-datepicker" (click)="pickerEnd.open()">
        <mat-label>{{'new_incidents.search.to'|translate}}</mat-label>
        <input matInput [matDatepicker]="pickerEnd" formControlName="date_end" readonly>
        <div matSuffix class="d-flex align-items-center">
          <mat-datepicker-toggle [for]="pickerEnd"></mat-datepicker-toggle>
        </div>
        <mat-datepicker #pickerEnd></mat-datepicker>
      </mat-form-field>

      <div class="results-info small alert alert-info" [innerHtml]="'new_incidents.results_info' | translate"></div>
    </div>
  </form>
</mat-card>

<mat-card class="w-100 mt-3">
  <h2>{{'new_incidents.list_title' | translate}}</h2>

  <div class="incident-card" *ngFor="let incident of incidents" [ngClass]="{'selected': incident.selected }"
    (click)="incident.selected = !incident.selected">

    <mat-card-header>
      <mat-card-title>
        <div class="d-flex justify-content-between align-items-start">
          <div>
            <div class="station-name">{{ incident.station.info.site_name }}</div>
            <div class="d-flex align-items-center">
              <div class="mr-2" [ngClass]="'status-bullet mini ' + incident.station.status.code"></div>
              <div class="text light">{{incident.station.info.brand?.label}}</div>
            </div>
          </div>
          <div class="station-code">{{incident.station.code_implant}}</div>
        </div>
      </mat-card-title>
      <mat-card-subtitle>{{ incident.created_at | date :'dd/MM/y - HH:mm' }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div>
        <div>{{'car_incident.description' | translate}}</div>
        <div class="text light">{{incident.description}}</div>

        <div class="mt-2" *ngIf="incident.actions_taken">{{'car_incident.actions' | translate}}</div>
        <div class="text light">{{incident.actions_taken}}</div>
      </div>
      <button mat-icon-button class="info-button" (click)="showInfo($event, incident)">
        <mat-icon>info</mat-icon>
      </button>
    </mat-card-content>
  </div>
</mat-card>
