import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '@ff/user';
import { LoaderService } from '@ff/loader';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-auth-reset-password-link',
  templateUrl: './resetPasswordLink.component.html',
  styleUrls: ['./resetPasswordLink.component.scss']
})
export class ResetPasswordLinkComponent implements OnInit {

  @Input() token: string;

  public form;
  public resetPasswordLinkSuccessMessage: string;
  public resetPasswordLinkErrorMessage: any;
  public resetPasswordLinkForm: FormGroup;
  public error: any;

  constructor(
    fb: FormBuilder,
    private _userService: UserService,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<ResetPasswordLinkComponent>,
    private _loaderService: LoaderService,
    private translate: TranslateService
  ) {
    this.error = {};
    this.form = fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  public ngOnInit() {
  }

  public submit() {
    if (this.form.valid && !this._loaderService.isVisible()) {
      this._loaderService.show();
      this._userService.resetPasswordLink(this.form).then(
        (response) => {
          this.form.reset();
          this._loaderService.hide();
          this._snackBar.open(this.translate.instant('forgot_password.messages.' + response.message));
          this._dialogRef.close();
        }
      ).catch(
        (response) => {
          this._loaderService.hide();
          const message = response.error.errors ? response.error.errors.password[0] : response.error.message;
          this._snackBar.open(message);
        }
      );
    }
  }
}
