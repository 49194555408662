import { HttpClient } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { API_CONFIG } from '@ff/api';
import { ATInternetModule } from '@ff/at-internet';
import { AuthModule, AUTH_CONFIG } from '@ff/auth';
import { OAUTH2_CONFIG } from '@ff/oauth2';
import { UserModule, USER_CONFIG } from '@ff/user';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { AuthModule as AppAuthModule } from '../auth/auth.module';
import { SharedModule } from '../shared/shared.module';
import { UserModule as AppUserModule } from '../user/user.module';
import { AppComponent } from './components/app/app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { routes } from './core.routes';
import { CustomServerTranslateLoader } from './custom-server-translate.loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): CustomServerTranslateLoader {
    return new CustomServerTranslateLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        NavigationComponent,
    ],
    providers: [
        { provide: API_CONFIG, useValue: { host: environment.api.host } },
        { provide: USER_CONFIG, useValue: { host: environment.api.host } },
        { provide: AUTH_CONFIG, useValue: { host: environment.api.host } },
        {
            provide: OAUTH2_CONFIG,
            useValue: {
                host: environment.api.host,
                endpoints: {
                    bearer: '/oauth2/token',
                    providers: '/oauth2/providers'
                }
            }
        }
    ],
    imports: [

        // FF packages

        UserModule,
        AuthModule,
        ATInternetModule,

        // Angular Packages
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routes),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),

        // App packages

        SharedModule,
        AppAuthModule,
        AppUserModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    bootstrap: [
        AppComponent
    ]
})
export class CoreModule {
    public constructor(@Optional() @SkipSelf() parent: CoreModule) {
        if (parent) {
            throw new Error('CoreModule should not be exported then imported by another module!');
        }
    }
}
