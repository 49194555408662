import { SeverityLevel } from './severity-level.model';

// tslint:disable:variable-name
export class IncidentType {

  id: number;
  description: string;
  type: string;
  reflex_sheet: string;
  is_active: boolean;

  severity_levels?: SeverityLevel[];

  constructor(data = {}) {
    Object.assign(this, data);
    this.severity_levels = this.severity_levels?.map(sl => new SeverityLevel(sl));
  }
}
