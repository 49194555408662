import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Provider } from './../../../../models/provider.model';
import { ProviderBottomSheetComponent } from './provider-bottom-sheet/provider-bottom-sheet.component';

@Component({
  selector: 'app-providers-list',
  templateUrl: './providers-list.component.html',
  styleUrls: ['./providers-list.component.scss']
})
export class ProvidersListComponent implements OnInit {

  @Input() providers: Provider[];
  @Input() ignoreIsDisplayed?: boolean;
  @Output() clickToCalled: EventEmitter<{ channel: string, contactId: number }>
    = new EventEmitter<{ channel: string, contactId: number }>();

  constructor(
    private bottomSheet: MatBottomSheet
  ) { }

  ngOnInit(): void {
  }

  clickToCall(channel: string, contactId: number): void {
    this.clickToCalled.emit({ channel, contactId });
  }

  openProviderBottomSheet(provider: Provider): void {
    const sheetRef = this.bottomSheet.open(ProviderBottomSheetComponent, {
      data: { provider }
    });

    sheetRef.afterDismissed().subscribe((data) => {
      if (!data) { return; }
      this.clickToCall(data.channel, data.providerId);
    })
  }

  hasProvidersToShow(): boolean {
    const filteredList = this.ignoreIsDisplayed
      ? this.providers
      : this.providers.filter(provider => provider.provider_type.is_displayed);

    return this.providers.length > 0 && filteredList.length > 0;
  }

  isProviderDisplayed(provider: Provider): boolean {
    return this.ignoreIsDisplayed || provider.provider_type.is_displayed;
  }
}
