import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderService } from '@ff/loader';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { User } from './../../../../models/user.model';
import { DatetimeHelperService } from './../../../shared/services/datetime-helper.service';
import { RoleService } from './../../../shared/services/role.service';
import { IncidentService } from './../../services/incident.service';



@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  searchForm: FormGroup;
  ctcs: any[] = [];

  currentUser: User;
  users: User[];

  constructor(
    private fb: FormBuilder,
    private datetimeHelper: DatetimeHelperService,
    private incidentService: IncidentService,
    private roleService: RoleService,
    private loader: LoaderService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.roleService.getUser();
    this.initData();
    this.initForm();
    this.searchCtcs();
  }

  searchCtcs(): void {
    const params = this.getHistoryParams();
    this.loader.show();
    this.incidentService.search({ user_id: params.userId, date_start: params.dateStart, date_end: params.dateEnd })
      .subscribe(
        (ctcs: any[]) => {
          this.ctcs = ctcs.map((ctc) => ({
            ...ctc,
            selected: true,
          }
          ));
          this.loader.hide();
        },
        (error) => { this.loader.hide(); },
        () => { this.loader.hide(); }
      );
  }

  canCreateReport(): boolean {
    return this.ctcs?.length > 0 && this.ctcs.some(ctc => ctc.selected === true);
  }

  generateReport(): boolean {
    const excludedCtcsIds = this.ctcs.filter(ctc => ctc.selected !== true)
      .map(inc => ({ id: inc.id, object_type: inc.object_type }));
    if (excludedCtcsIds.length === this.ctcs.length) { return; }

    const params = this.getHistoryParams();

    this.loader.show();
    this.incidentService.generateReport({
      user_id: params.userId, date_start: params.dateStart, date_end: params.dateEnd, excluded_ids: excludedCtcsIds
    }).subscribe(
      () => {
        this.loader.hide();
        this.snackBar.open(this.translate.instant('new_incidents.report_generated'));
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  isAdminUser(): boolean {
    return this.currentUser?.role.code === 'ADMIN';
  }

  isMobile(phoneNumber: string): boolean {
    return phoneNumber.startsWith('06') || phoneNumber.startsWith('07');
  }

  getUsername(user: User): string {
    return user.last_name.toLocaleUpperCase() + ' ' + user.first_name;
  }

  isVisitor(user: User): boolean {
    return user.role.code.toLowerCase() === 'visitor';
  }

  isVisitorOrVisitorDCM(user: User): boolean {
    return user.role.code.toLowerCase() === 'visitor' || user.role.code.toLowerCase() === 'visitordcm';
  }

  private initForm(): void {
    const dateStart = this.datetimeHelper.getCurrentDateMoment().subtract(1, 'week').toISOString();
    const userId = this.isAdminUser() ? -1 : this.currentUser.id;

    this.searchForm = this.fb.group({
      user_id: [userId, Validators.required],
      date_start: [dateStart, Validators.required],
      date_end: [this.datetimeHelper.getIsoDateString(), Validators.required]
    });

    merge(
      this.searchForm.valueChanges
    ).subscribe(() => {
      if (this.searchForm.get('date_start').value && this.searchForm.get('date_end').value) {
        this.searchCtcs();
      }
    });
  }

  private getHistoryParams(): any {
    const formValue = this.searchForm.getRawValue();
    const dateStart = this.datetimeHelper.getIsoDateString(formValue.date_start);
    const dateEnd = this.datetimeHelper.getIsoDateString(formValue.date_end);
    const userId = formValue.user_id;
    return { userId, dateStart, dateEnd };
  }

  private initData(): void {
    this.roleService.getAllUsers().subscribe((users: User[]) => {
      users.sort((a, b) => a.last_name.localeCompare(b.last_name))
      this.users = users;
    });
  }
}
