import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { LoaderService } from '@ff/loader';
import { OAuth2Service } from '@ff/oauth2';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResetPasswordLinkComponent } from '../reset-password-link/resetPasswordLink.component';
import { ResetPasswordComponent } from '../reset-password/resetPassword.component';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/modules/main/services/home.service';
import { ConfigurationService } from 'src/app/modules/shared/services/configuration.service';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public visitorLoginForm: FormGroup;
  public error: any;
  

  /**
   * Reset password link modal
   */
  public showResetPasswordLinkModal: boolean;

  /**
   * Reset password modal
   */
  public showResetPasswordModal: boolean;

  public token: string;

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _matDialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _loaderService: LoaderService,
    private _oauth2: OAuth2Service,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private _homeService: HomeService,
    private configurationService: ConfigurationService,
    private fileSaver: FileSaverService
  ) {
    this.error = {};

    const cguChecked: boolean = false;
    const privacyChecked: boolean = false;

    this.loginForm = fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      //cgu_checkbox: [cguChecked],
      //privacy_checkbox: [privacyChecked],
    });

    this.visitorLoginForm = fb.group({
      email: ['visitor@ar.com', Validators.required],
      password: ['visitor', Validators.required]
    });

    /*this.loginForm.get('cgu_checkbox').valueChanges.subscribe(response => {
      localStorage.setItem('pob_cgu_checked', response);
  });

  this.loginForm.get('privacy_checkbox').valueChanges.subscribe(response => {
      localStorage.setItem('pob_privacy_checked', response);
  });*/

    this.showResetPasswordLinkModal = false;

    this._activatedRoute.queryParams.subscribe(
      (params: any) => {
        if (params.token) {
          this.token = params.token;
          this.showResetPasswordDialog();
        }
      }
    );

    if (this._userService.getUser().getValue()) {
      this._router.navigate(['/home']);
    }
  }

  public loginWithOAuth2(url: string): void {
    this._loaderService.show();
    this._oauth2.login(url);
  }

  public getOAuth2Providers(): BehaviorSubject<any> {
    return this._oauth2.getProviders();
  }

  public redirect(url: string): void {
    window.location.href = url;
  }

  public login(form: FormGroup): void {
    // Prevent spam submit
    if (form.valid && !this._loaderService.isVisible()) {
      this._loaderService.show();

      this._authService.login(form).then(
        (user: any) => {
          this._loaderService.hide();
          this._router.navigate(['/home']);
        }
      ).catch(
        (response) => {
          this._loaderService.hide();
          this._snackBar.open(response.error.message);
        }
      );
    }
  }

  public loginVisitor(): void {
    this.login(this.visitorLoginForm);
  }

  public showResetPasswordLinkDialog(): void {
    this._matDialog.open(ResetPasswordLinkComponent);
  }

  public showResetPasswordDialog(): void {
    this._matDialog.open(ResetPasswordComponent, {
      data: { token: this.token }
    });
  }

  ngOnInit(): void {
  }

  openDoc(docName: string): void {
    this._loaderService.show();
    this.configurationService.getDocumentFile(docName, true).subscribe(
        (blob: Blob) => {
            const ext = docName === 'planning' ? 'xlsx' : 'pdf';
            const type = docName === 'planning' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf';
            this.fileSaver.save(blob, `${docName}.${ext}`, `${type}`);
            this._loaderService.hide();
        },
        (err) => {
            this._loaderService.hide();
            this._snackBar.open(this.translateService.instant('general.messages.errors.generic'));
        },
        () => { this._loaderService.hide(); }
    );
  }

}
