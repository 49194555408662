import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Contact } from '../../../../models/contact.model';
import { ContactService } from '../../services/contact.service';
import { SharedService } from './../../../shared/services/shared.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  searchContactForm: FormGroup;

  searchContactsResults: Contact[];
  isLoadingResults = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private contactService: ContactService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  clearSearch(): void {
    this.searchContactForm.reset();
    this.searchContactForm.updateValueAndValidity();
    this.sharedService.clearSearch(this.sharedService.SEARCH_CONTACT_LS_KEY_SUFFIX);
  }

  searchContacts(): void {
    const query = this.searchContactForm.get('query').value;
    if (!query?.trim()) {
      this.searchContactsResults = undefined;
      return;
    }

    this.sharedService.setSearch(this.sharedService.SEARCH_CONTACT_LS_KEY_SUFFIX, query);

    this.isLoadingResults = true;
    this.contactService.search(query).subscribe(
      (results: Contact[]) => {
        this.searchContactsResults = results;
      },
      (error) => { this.isLoadingResults = false; },
      () => { this.isLoadingResults = false; });
  }

  private initForm(): void {
    const savedSearches = this.sharedService.getSearches();
    const savedQuery = savedSearches[this.sharedService.SEARCH_CONTACT_LS_KEY_SUFFIX];

    this.searchContactForm = this.fb.group({
      query: [savedQuery]
    });

    if (savedQuery) {
      this.searchContacts();
    }

    this.searchContactForm.get('query').valueChanges.subscribe(() => {
      this.searchContacts();
    });
  }

}
