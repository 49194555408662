<div class="main-content">
  <h1>{{'navigation.providers' | translate}}</h1>

  <form [formGroup]="searchProviderForm" (ngSubmit)="filterProviders()" novalidate>
    <mat-card class="mb-2">
      <mat-form-field>
        <mat-label>{{'providers.search.provider' | translate}}</mat-label>
        <input matInput formControlName="query" name="providers_search">
        <mat-icon color="primary" matSuffix *ngIf="!searchProviderForm.get('query').value?.trim()"
          (click)="searchProviders()" class="pointer">search</mat-icon>
        <mat-icon color="primary" matSuffix *ngIf="searchProviderForm.get('query').value?.trim()"
          (click)="clearSearch()" class="pointer">clear</mat-icon>
        <mat-hint>{{'providers.search.provider_hint' | translate}}</mat-hint>
      </mat-form-field>
    </mat-card>
  </form>

  <ng-container *ngIf="filteredProviders">
    <mat-spinner class="mx-auto my-2" *ngIf="isLoadingData; else resultsListTemplate"></mat-spinner>
    <ng-template #resultsListTemplate>
      <h3 class="mt-3">{{'providers.search.results.providers' | translate}}</h3>
    </ng-template>
    <mat-card class="my-3">
      <app-providers-list [providers]="filteredProviders" [ignoreIsDisplayed]="true"></app-providers-list>
    </mat-card>
  </ng-container>
</div>
