import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '@ff/auth';
import { TranslateService } from '@ngx-translate/core';
import { absoluteRoutesNames } from 'src/app/modules/shared/absolute.routes';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    public snackBar: MatSnackBar,
    private authService: AuthService,
    private zone: NgZone,
    private translate: TranslateService,
    private router: Router
  ) { }

  public handleError(error: HttpErrorResponse, ignoreOffline?: boolean): Promise<any> {
    let message = this.extractErrorMessage(error);

    if (error.status === 504 && !ignoreOffline) {
      message = this.translate.instant('general.errors.offline');
    }

    if (error.status === 0 || error.status === 401) {
      message = this.translate.instant('general.messages.errors.unauthenticated');
      this.logoutUser();
    }

    this.snackBarOpen(message);

    return Promise.reject(message);
  }

  public showGenericError(): void {
    this.zone.run(() => {
      this.snackBar.open(this.translate.instant('general.errors.generic_error'));
    });
  }

  private snackBarOpen(message): void {
    this.zone.run(() => {
      this.snackBar.open(message);
    });
  }

  private logoutUser(): void {
    this.authService.logout()
      .then(() => { })
      .catch((error) => { })
      .finally(() => { this.router.navigate([absoluteRoutesNames.LOGOUT]); });
  }

  private extractErrorMessage(error): string {
    const message = error?.error?.message || error?.error?.error || error?.message
      || 'general.errors.generic_error';
    return this.translate.instant(message);
  }
}
