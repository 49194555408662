import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { RoleService } from '../../services/role.service';
import { Contact } from './../../../../models/contact.model';
import { ContactService } from './../../../main/services/contact.service';
import { ContactBottomSheetComponent } from './contact-bottom-sheet/contact-bottom-sheet.component';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss']
})
export class ContactsListComponent implements OnInit {

  @Input() contacts: Contact[];
  @Input() onlyTel?: boolean;
  @Input() onlySms?: boolean;
  @Input() onlyEmail?: boolean;
  @Input() ignoreIsDisplayed?: boolean;
  @Input() hideMultiEmail?: boolean;

  @Output() clickToCalled: EventEmitter<{ channel: string, contactId: number }>
    = new EventEmitter<{ channel: string, contactId: number }>();

  constructor(
    private bottomSheet: MatBottomSheet,
    private contactService: ContactService,
    private roleService: RoleService,
  ) {
  }

  ngOnInit(): void {
  }

  isVisitor(): boolean {
    return this.roleService.isVisitor();
  }

  updateFavoriteContact(event: Event, contactId: number): void {
    event.stopPropagation();

    this.contactService.updateFavorite(contactId).subscribe(
      (isFavorite: boolean) => {
        const contact = this.contacts.find(contactItem => contactItem.id === contactId);
        contact.is_favorite = isFavorite;
      },
      (error) => {
      }
    );
  }

  clickToCall(channel: string, contactId: number): void {
    this.clickToCalled.emit({ channel, contactId });
  }

  openContactBottomSheet(contact: Contact): void {
    const sheetRef = this.bottomSheet.open(ContactBottomSheetComponent, {
      data: { contact, onlyEmail: this.onlyEmail, onlyTel: this.onlyTel, onlySms: this.onlySms, hideMultiEmail: this.hideMultiEmail }
    });

    sheetRef.afterDismissed().subscribe((data) => {
      if (!data) { return; }
      this.clickToCall(data.channel, data.contactId);
    })
  }

  hasContactsToShow(): boolean {
    const filteredList = this.ignoreIsDisplayed
      ? this.contacts
      : this.contacts.filter(contact => contact.contact_function.is_displayed);

    return this.contacts.length > 0 && filteredList.length > 0;
  }

  isContactDisplayed(contact: Contact): boolean {
    return this.ignoreIsDisplayed || contact.contact_function.is_displayed;
  }
}
