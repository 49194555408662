import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { endpoints } from 'src/environments/endpoints';
import { environment } from 'src/environments/environment';
import { Contact } from './../../../models/contact.model';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  search(query: string): Observable<Contact[]> {
    return this.http.post(
      `${environment.api.host}${endpoints.contacts}${endpoints.search}`, { query }
    ).pipe(
      map(
        (res: any) => res.data.contacts.map(contact => new Contact(contact))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getFavorites(): Observable<Contact[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.contact_favorites}`
    ).pipe(
      map(
        (res: any) => res.data.contact_favorites.map(favContact => new Contact(favContact))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  updateFavorite(contactId: number): Observable<any> {
    return this.http.put(
      `${environment.api.host}${endpoints.contact_favorites}`, { contact_id: contactId }
    ).pipe(
      map(
        (res: any) => res.data.is_favorite
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }
}
