import { Routes } from '@angular/router';
import { LoggedInGuard } from '@ff/core';
import { CarIncidentComponent } from './components/car-incident/car-incident.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { GroupedContactComponent } from './components/grouped-contact/grouped-contact.component';
import { HistoryComponent } from './components/history/history.component';
import { HomeComponent } from './components/home/home.component';
import { IncidentCardComponent } from './components/incident-card/incident-card.component';
import { NewIncidentsComponent } from './components/new-incidents/new-incidents.component';
import { ProvidersComponent as ProvidersComponent } from './components/providers/providers.component';
import { StationComponent } from './components/station/station.component';
import { mainRoutesNames } from './main.routes.names';

export const mainRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: mainRoutesNames.HOME, component: HomeComponent,
                data: { title: 'home', icon: 'home', visitorAccess: true }
            },
            {
                path: mainRoutesNames.FAVORITES, component: FavoritesComponent,
                data: { title: 'favorites', icon: 'star', visitorAccess: true }
            },
            {
                path: mainRoutesNames.CONTACTS, component: ContactsComponent,
                data: { title: 'contacts', icon: 'groups', visitorAccess: true }
            },
            {
                path: mainRoutesNames.PROVIDERS, component: ProvidersComponent,
                data: { title: 'providers', icon: 'groups' }
            },
            {
                path: mainRoutesNames.GROUPED_CONTACT, component: GroupedContactComponent,
                data: { title: 'grouped_contact', icon: 'group_work', visitorAccess: true, hiddenForRoles: 'car' }
            },
            {
                path: mainRoutesNames.NEW_INCIDENTS, component: NewIncidentsComponent,
                data: { title: 'new_incidents', icon: 'move_to_inbox' }
            },
            {
                path: mainRoutesNames.HISTORY, component: HistoryComponent,
                data: { title: 'history', icon: 'summarize', hiddenForRoles: 'car' }
            },
            { path: mainRoutesNames.INCIDENT_CARD + '/:station_id', component: IncidentCardComponent },
            { path: mainRoutesNames.CAR_INCIDENT + '/:station_id', component: CarIncidentComponent },
            { path: mainRoutesNames.STATION + '/:id', component: StationComponent },
        ],
        canActivate: [LoggedInGuard],
        canLoad: [LoggedInGuard],
    }
];
