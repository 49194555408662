<header>
  <div class="header-left">
    <ng-container *ngIf="userService.getUser() | async; else logoTemplate">
      <div class="menu-and-appname">
        <mat-icon color="primary" class="menu-open mr-4" (click)="toggleNav()">menu</mat-icon>
        <span class="app-title">{{'general.app_name' | translate}}</span>
      </div>
    </ng-container>
    <ng-template #logoTemplate>
      <a [routerLink]="'/app/home'" class="home-link">
        <img src="assets/logo.svg" title="TotalEnergies" alt="TotalEnergies" class="logo" height="76" />
      </a>
    </ng-template>
  </div>
  <div class="header-right">
    <div class="header-right-top">
      <div class="header-info-container">
        <ng-container *ngIf="userService.getUser() | async">
          <span class="pointer d-flex align-items-center user" [matMenuTriggerFor]="account">
            <mat-icon class="ml-2 user-icon" color="primary">more_vert</mat-icon>
          </span>
          <mat-menu class="mt-1" #account="matMenu" [overlapTrigger]="false">
            <button mat-menu-item disabled>
              <mat-icon class="mr-1 user-icon">account_circle</mat-icon>
              {{userService.getUser().getValue().first_name}} {{userService.getUser().getValue().last_name}}
            </button>
            <button mat-menu-item routerLink="/auth/logout">
              <mat-icon class="mr-1 user-icon">logout</mat-icon>
              {{'header.logout' | translate}}
            </button>
          </mat-menu>
        </ng-container>
      </div>
    </div>
  </div>
</header>
