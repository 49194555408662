import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { absoluteRoutesNames } from 'src/app/modules/shared/absolute.routes';
import { StationsListItem } from './../../../../../models/stations-list-item.model';
import { StationService } from './../../../services/station.service';

@Component({
  selector: 'app-favorite-stations',
  templateUrl: './favorite-stations.component.html',
  styleUrls: ['./favorite-stations.component.scss']
})
export class FavoriteStationsComponent implements OnInit {

  isLoadingData: boolean;

  favoriteStations: StationsListItem[];
  favoritesToRemoveIds: number[] = [];

  constructor(
    private router: Router,
    private stationService: StationService
  ) { }

  ngOnInit(): void {
    this.stationService.getFavorites().subscribe((stations: StationsListItem[]) => {
      this.favoriteStations = stations;
    });
  }

  isBeingRemoved(stationId: number): boolean {
    return this.favoritesToRemoveIds.includes(stationId);
  }

  removeFavoriteStation(event: Event, stationId: number): boolean {
    event.stopPropagation();

    if (this.isBeingRemoved(stationId)) { return; }

    this.favoritesToRemoveIds.push(stationId);
    const favIdIndex = this.favoritesToRemoveIds.indexOf(stationId);
    const favIndex = this.favoriteStations.findIndex(station => station.id === stationId);

    this.stationService.updateFavorite(stationId).subscribe(
      () => {
        this.favoriteStations.splice(favIndex, 1);
        this.favoritesToRemoveIds.splice(favIdIndex, 1);
      },
      (error) => {
        this.favoritesToRemoveIds.splice(favIdIndex, 1);
      }
    );
  }

  gotoStation(stationId: number): void {
    this.router.navigate([absoluteRoutesNames.STATION, stationId]);
  }
}
