<div class="incident-card" *ngIf="ctc.comment" [ngClass]="{'selected': ctc.selected }"
    (click)="ctc.selected = !ctc.selected">
    <mat-card-header>
      <mat-card-title>
        <div class="d-flex justify-content-center pb-1 text light">{{ctc.created_at | date: 'dd/MM/y HH:mm:ss'}}
        </div>
        <div class="d-flex justify-content-between align-items-start">
          <div>
            <div class="station-name">{{ ctc.clicktocall.station.info.site_name }}</div>
            <div class="d-flex align-items-center">
              <div class="mr-2" [ngClass]="'status-bullet mini ' + ctc.clicktocall.station.status.code"></div>
              <div class="text light">{{ctc.clicktocall.station.info.brand?.label}}</div>
            </div>
          </div>
          <div>
            <div class="station-code">{{ctc.clicktocall.station.code_implant}}</div>
            <!-- <div *ngIf="ctc.clicktocall.incident">
              {{ctc.clicktocall.incident.declaration_date |
              date:'dd/MM/y'}}&nbsp;{{ctc.clicktocall.incident.declaration_time|slice:0:-3}}
            </div> -->
          </div>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="flex flex-column" *ngIf="ctc.clicktocall.incident">
        <div class="mb-2">
          <div class="incident-type">{{ ctc.clicktocall.incident.incident_type?.description }}</div>
          <div>{{(ctc.clicktocall.incident.severity_level?.level + 1) + ' - '}}{{ctc.matrice_description.length > 150 ?
            (ctc.matrice_description |slice:0:150) + '...' : ctc.matrice_description}}
          </div>
        </div>

        <div class="mb-2">
          <div class="incident-type">{{'incident.comment' | translate}}</div>
          <div>{{ctc.comment}}
          </div>
        </div>

        <div class="contact-infos">
          <div *ngIf="ctc.contact" class="d-flex align-items-center">
            <ng-container *ngIf="ctc.cellphone">
              <mat-icon class="mr-2">smartphone</mat-icon><span>{{ctc.contact.name}} : {{ctc.contact.cellphone}}</span>
            </ng-container>
            <ng-container *ngIf="ctc.sms">
              <mat-icon class="mr-2">sms</mat-icon><span>{{ctc.contact.name}} : {{ctc.contact.cellphone}}</span>
            </ng-container>
            <ng-container *ngIf="ctc.email">
              <mat-icon class="mr-2">email</mat-icon><span>{{ctc.contact.email}}</span>
            </ng-container>
            <ng-container *ngIf="ctc.multi_sms">
              <mat-icon>group_add</mat-icon>
              <mat-icon class="mr-2">sms</mat-icon><span>{{ctc.contacts_list_phones_names}}</span>
            </ng-container>
            <ng-container *ngIf="ctc.multi_email">
              <mat-icon>group_add</mat-icon>
              <mat-icon class="mr-2">email</mat-icon><span>{{ctc.contacts_list}}</span>
            </ng-container>
          </div>
          <div *ngIf="ctc.provider" class="d-flex align-items-center">
            <ng-container *ngIf="ctc.phone">
              <ng-container *ngIf="isMobile(ctc.provider.phone_1); else providerPhoneTemplate">
                <mat-icon class="mr-2">smartphone</mat-icon><span>{{ctc.provider.phone_1}}</span>
              </ng-container>
              <ng-template #providerPhoneTemplate>
                <mat-icon class="mr-2">phone</mat-icon><span>{{ctc.provider.phone_1}}</span>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="ctc.sms">
              <mat-icon class="mr-2">sms</mat-icon><span>{{ctc.provider.phone_1}}</span>
            </ng-container>
            <ng-container *ngIf="ctc.provider.email">
              <mat-icon class="mr-2">email</mat-icon><span>{{ctc.provider.email_1}}</span>
            </ng-container>
          </div>
          <div *ngIf="ctc.is_operator" class="d-flex align-items-center">
            <ng-container *ngIf="ctc.phone">
              <mat-icon class="mr-2">phone</mat-icon><span>{{ctc.clicktocall.station.info.phone}}</span>
            </ng-container>
            <ng-container *ngIf="ctc.email">
              <mat-icon class="mr-2">phone</mat-icon><span>{{ctc.clicktocall.station.info.email}}</span>
            </ng-container>
          </div>
          <div *ngIf="ctc.is_manager_1" class="d-flex align-items-center">
            <ng-container *ngIf="ctc.cellphone">
              <mat-icon class="mr-2">smartphone</mat-icon><span>{{ctc.clicktocall.station.info.manager_phone}}</span>
            </ng-container>
            <ng-container *ngIf="ctc.phone">
              <mat-icon class="mr-2">phone</mat-icon><span>{{ctc.clicktocall.station.info.manager_phone}}</span>
            </ng-container>
            <ng-container *ngIf="ctc.sms">
              <mat-icon class="mr-2">sms</mat-icon><span>{{ctc.clicktocall.station.info.manager_phone}}</span>
            </ng-container>
          </div>
          <div *ngIf="ctc.is_manager_2" class="d-flex align-items-center">
            <ng-container *ngIf="ctc.cellphone">
              <mat-icon class="mr-2">smartphone</mat-icon><span>{{ctc.clicktocall.station.info.manager_2_phone}}</span>
            </ng-container>
            <ng-container *ngIf="ctc.phone">
              <mat-icon class="mr-2">phone</mat-icon><span>{{ctc.clicktocall.station.info.manager_2_phone}}</span>
            </ng-container>
            <ng-container *ngIf="ctc.sms">
              <mat-icon class="mr-2">sms</mat-icon><span>{{ctc.clicktocall.station.info.manager_2_phone}}</span>
            </ng-container>
          </div>
        </div>
      </div>
    </mat-card-content>
  </div>