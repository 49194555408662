export const mainRoutesNames = {
    CAR_INCIDENT: 'incident/car',
    CONTACTS: 'contacts',
    FAVORITES: 'favorites',
    GROUPED_CONTACT: 'grouped-contact',
    HISTORY: 'history',
    HOME: 'home',
    INCIDENT_CARD: 'incident/station',
    NEW_INCIDENTS: 'new-incidents',
    PROVIDERS: 'providers',
    STATION: 'station',
};
