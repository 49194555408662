<div class="main-content">
  <h1>{{'navigation.favorites' | translate}}</h1>

  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <div>{{'favorites.stations.title'|translate}}</div>
      </ng-template>
      <ng-template matTabContent>
        <app-favorite-stations></app-favorite-stations>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div>{{'favorites.contacts.title'|translate}}</div>
      </ng-template>
      <ng-template matTabContent>
        <app-favorite-contacts></app-favorite-contacts>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
