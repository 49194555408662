import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Provider } from 'src/app/models/provider.model';
import { SharedService } from './../../../shared/services/shared.service';
import { ProviderService } from './../../services/provider.service';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent implements OnInit {

  private providers: Provider[] = [];

  searchProviderForm: FormGroup;
  filteredProviders: Provider[];
  isLoadingData = false;

  constructor(
    private fb: FormBuilder,
    private providerService: ProviderService,
    private translate: TranslateService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.initData();
  }

  clearSearch(): void {
    this.searchProviderForm.reset();
    this.searchProviderForm.updateValueAndValidity();
    this.sharedService.clearSearch(this.sharedService.SEARCH_PROVIDER_LS_KEY_SUFFIX);
  }

  filterProviders(): void {
    const query = this.searchProviderForm.get('query').value;
    if (!query?.trim()) {
      this.filteredProviders = this.providers;
      return;
    }

    this.sharedService.setSearch(this.sharedService.SEARCH_PROVIDER_LS_KEY_SUFFIX, query);

    this.filteredProviders = this.providers.filter(
      provider => (
        provider.company.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0
        || this.translate.instant('entities.provider_types.' + provider.provider_type.code)
          .toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0
        || provider.role?.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0
      )
    );
  }

  private initForm(): void {
    const savedSearches = this.sharedService.getSearches();
    const savedQuery = savedSearches[this.sharedService.SEARCH_PROVIDER_LS_KEY_SUFFIX];

    this.searchProviderForm = this.fb.group({
      query: [savedQuery]
    });

    this.searchProviderForm.get('query').valueChanges.subscribe(() => {
      this.filterProviders();
    });
  }

  private initData(): void {
    this.isLoadingData = true;
    this.providerService.getAll().subscribe(
      (results: Provider[]) => {
        this.providers = results.sort((a: Provider, b: Provider) => {
          const aLabel = this.translate.instant('entities.provider_types.' + a.provider_type.code).toLocaleLowerCase();
          const bLabel = this.translate.instant('entities.provider_types.' + b.provider_type.code).toLocaleLowerCase();
          return aLabel.localeCompare(bLabel);
        });
        this.filterProviders();
      },
      (error) => { this.isLoadingData = false; },
      () => { this.isLoadingData = false; });
  }
}
