<div mat-dialog-title class="d-flex justify-content-between align-items-center">
    <h2 class="p-0 m-0">
        {{'forgot_password.title' | translate}}
    </h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>

<form mat-dialog-content [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <p class="mt-3">{{'forgot_password.intro' | translate}}</p>
    <mat-form-field class="w-100">
        <input matInput prefixIcon="envelope-o" placeholder="email@domain.com" type="email" formControlName="email" />
    </mat-form-field>
</form>
<div mat-dialog-actions class="d-flex justify-content-start mb-0">

    <button mat-flat-button color="primary" class="text-center" [disabled]="!form.valid" (click)="submit()">
        {{'forgot_password.send' | translate}}
    </button>
</div>
