<mat-card class="my-3">
  <mat-spinner class="mx-auto my-2" *ngIf="isLoadingData; else stationsListTemplate"></mat-spinner>
  <ng-template #stationsListTemplate>
    <div class="items-list" *ngIf="favoriteStations?.length > 0; else noFavoriteStationsTemplate">
      <div *ngFor="let station of favoriteStations" (click)="gotoStation(station.id)" class="item pointer">
        <div class="d-flex justify-content-between align-content-end">
          <div class="item-name text">{{station.site_name}}</div>
          <mat-icon class="favorite-star" (click)="removeFavoriteStation($event, station.id)">
            {{isBeingRemoved(station.id)? 'star_outline' : 'star'}}
          </mat-icon>
        </div>
        <div class="d-flex justify-content-between align-content-end">
          <div class="d-flex align-items-center">
            <div class="mr-2" [ngClass]="'status-bullet ' + station.status.code"></div>
            <div class="text light">{{station.brand?.label}}</div>
          </div>
          <div class="d-flex align-items-center">
            <div class="text light">{{station.code_implant}}</div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #noFavoriteStationsTemplate>
      <span class="text light">{{'favorites.stations.no_stations' | translate}}</span>
    </ng-template>
  </ng-template>
</mat-card>
