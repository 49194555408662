<div class="main-content">
  <h1>{{'navigation.home' | translate}}</h1>

  <form [formGroup]="searchStationForm" (ngSubmit)="searchStations()" novalidate>
    <mat-card class="mb-3">
      <mat-form-field>
        <mat-label>{{'home.search.station' | translate}}</mat-label>
        <input matInput formControlName="query" name="stations_search">
        <mat-icon color="primary" matSuffix *ngIf="!searchStationForm.get('query').value?.trim()"
          (click)="searchStations()" class="pointer">search</mat-icon>
        <mat-icon color="primary" matSuffix *ngIf="searchStationForm.get('query').value?.trim()" (click)="clearSearch()"
          class="pointer">clear</mat-icon>
        <mat-hint>{{'home.search.station_hint' | translate}}</mat-hint>
      </mat-form-field>
    </mat-card>
  </form>

  <mat-spinner class="mx-auto my-3" *ngIf="isLoadingResults; else resultsListTemplate"></mat-spinner>
  <ng-template #resultsListTemplate>
    <ng-container *ngIf="searchStationsResults">
      <app-stations-list [stations]="searchStationsResults"></app-stations-list>
    </ng-container>
  </ng-template>
</div>
