import { CarIncident } from './../../../../models/car-incident.model';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from '@ff/loader';
import { merge } from 'rxjs';
import { Incident } from 'src/app/models/incident.model';
import { absoluteRoutesNames } from './../../../shared/absolute.routes';
import { DatetimeHelperService } from './../../../shared/services/datetime-helper.service';
import { IncidentService } from './../../services/incident.service';

@Component({
  selector: 'app-new-incidents',
  templateUrl: './new-incidents.component.html',
  styleUrls: ['./new-incidents.component.scss']
})
export class NewIncidentsComponent implements OnInit {

  searchForm: FormGroup;
  incidents: CarIncident[] = [];

  constructor(
    private fb: FormBuilder,
    private datetimeHelper: DatetimeHelperService,
    private incidentService: IncidentService,
    private router: Router,
    private loader: LoaderService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.searchCarIncidents();
  }

  searchCarIncidents(): void {
    const formValue = this.searchForm.getRawValue();
    const dateStart = this.datetimeHelper.getIsoDateString(formValue.date_start);
    const dateEnd = this.datetimeHelper.getIsoDateString(formValue.date_end);

    this.incidentService.searchCarIncidents({ date_start: dateStart, date_end: dateEnd }).subscribe((incidents: CarIncident[]) => {
      this.incidents = incidents.map(incident => ({ ...incident, selected: true }));
    });
  }

  showInfo(event, incident): void {
    event.stopPropagation();

    this.router.navigate([absoluteRoutesNames.STATION, incident.station.id]);
  }

  canCreateReport(): boolean {
    return this.incidents?.length > 0 && this.incidents.some(incident => incident.selected === true);
  }

  generateReport(): boolean {
    const selectedIncidentsIds = this.incidents.filter(incident => incident.selected === true).map(inc => inc.id);
    if (selectedIncidentsIds.length < 1) { return; }

    const dates = this.searchForm.getRawValue();

    this.loader.show();
    this.incidentService.generateCarReport({...dates, selected_ids: selectedIncidentsIds}).subscribe(
      () => {
        this.snackBar.open(this.translate.instant('new_incidents.report_generated'));
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  private initForm(): void {
    const dateStart = this.datetimeHelper.getCurrentDateMoment().subtract(1, 'week').toISOString();
    this.searchForm = this.fb.group({
      date_start: [dateStart, Validators.required],
      date_end: [this.datetimeHelper.getIsoDateString(), Validators.required]
    });

    merge(
      this.searchForm.get('date_start').valueChanges,
      this.searchForm.get('date_end').valueChanges
    ).subscribe(() => {
      if (this.searchForm.get('date_start').value && this.searchForm.get('date_end').value) {
        this.searchCarIncidents();
      }
    });
  }
}
