<div class="main-content">
  <div class="text-center">
    <h2 class="pb-0">{{ station?.info.site_name}}</h2>
    <h3 class="pt-0">{{station?.code_implant}}</h3>
  </div>

  <form class="mb-3" [formGroup]="carIncidentForm" (ngSubmit)="submitCarIncident()" novalidate>
    <mat-card class="mb-3">
      <mat-form-field class="mb-4">
        <mat-label>{{'car_incident.description' | translate}}</mat-label>
        <textarea matInput formControlName="description" rows="3"></textarea>
        <mat-hint>{{'car_incident.description_hint' | translate}}</mat-hint>
        <mat-error align="end" *ngIf="carIncidentForm.get('description').hasError('maxlength');">
          {{'car_incident.description_length_hint' | translate: {number: getInputLength('description')} }}
        </mat-error>

      </mat-form-field>
      <mat-form-field class="mb-4">
        <mat-label>{{'car_incident.actions' | translate}}</mat-label>
        <textarea matInput formControlName="actions_taken" rows="3"></textarea>
        <mat-hint>{{'car_incident.actions_hint' | translate}}</mat-hint>
        <mat-error align="end" *ngIf="carIncidentForm.get('actions_taken').hasError('maxlength');">
          {{'car_incident.actions_length_hint' | translate: {number: getInputLength('actions_taken')} }}
        </mat-error>
      </mat-form-field>
    </mat-card>

    <mat-card class="mb-3">
      <h3>{{'car_incident.contacts_title' | translate}}</h3>

      <mat-form-field>
        <mat-label>{{'car_incident.dcm_contact' | translate}}</mat-label>
        <mat-select formControlName="dcm_id">
          <mat-option *ngFor="let dcmUser of dcmUsers" [value]="dcmUser.id">
            {{ getContactName(dcmUser) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{'car_incident.com_contact' | translate}}</mat-label>
        <mat-select formControlName="com_id">
          <mat-option *ngFor="let comUser of comUsers" [value]="comUser.id">
            {{ getContactName(comUser) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card>

    <div class="d-flex justify-content-center">
      <button mat-flat-button color="primary" [disabled]="!isCarIncidentFormValid()">
        {{'general.buttons.validate' | translate}}
      </button>
    </div>
  </form>
</div>
