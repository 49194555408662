import { Component, OnInit } from '@angular/core';
import { Contact } from './../../../../../models/contact.model';
import { ContactService } from './../../../services/contact.service';


@Component({
  selector: 'app-favorite-contacts',
  templateUrl: './favorite-contacts.component.html',
  styleUrls: ['./favorite-contacts.component.scss']
})
export class FavoriteContactsComponent implements OnInit {

  isLoadingData: boolean;

  favoriteContacts: Contact[];
  favoritesToRemoveIds: number[] = [];

  constructor(
    private contactService: ContactService
  ) { }

  ngOnInit(): void {
    this.isLoadingData = true;
    this.contactService.getFavorites().subscribe(
      (contacts: Contact[]) => {
        this.favoriteContacts = contacts;
        this.isLoadingData = false;
      },
      (error) => { this.isLoadingData = false; },
      () => { this.isLoadingData = false; }
    );
  }

  isBeingRemoved(contactId: number): boolean {
    return this.favoritesToRemoveIds.includes(contactId);
  }

  removeFavoriteContact(event: Event, contactId: number): boolean {
    event.stopPropagation();

    if (this.isBeingRemoved(contactId)) { return; }

    this.favoritesToRemoveIds.push(contactId);
    const favIdIndex = this.favoritesToRemoveIds.indexOf(contactId);
    const favIndex = this.favoriteContacts.findIndex(contact => contact.id === contactId);

    this.contactService.updateFavorite(contactId).subscribe(
      () => {
        this.favoriteContacts.splice(favIndex, 1);
        this.favoritesToRemoveIds.splice(favIdIndex, 1);
      },
      (error) => {
        this.favoritesToRemoveIds.splice(favIdIndex, 1);
      }
    );
  }
}
