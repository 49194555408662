import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StationsListItem } from '../../../../models/stations-list-item.model';
import { SharedService } from './../../../shared/services/shared.service';
import { HomeService } from './../../services/home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  searchStationForm: FormGroup;

  searchStationsResults: StationsListItem[];
  isLoadingResults = false;

  constructor(
    private fb: FormBuilder,
    private homeService: HomeService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  searchStations(): void {
    const query = this.searchStationForm.get('query').value;
    if (!query?.trim()) {
      this.searchStationsResults = undefined;
      return;
    }

    this.sharedService.setSearch(this.sharedService.SEARCH_STATION_LS_KEY_SUFFIX, query);

    this.isLoadingResults = true;
    this.homeService.searchStations(query).subscribe(
      (results: StationsListItem[]) => {
        this.searchStationsResults = results;
      },
      (error) => { this.isLoadingResults = false; },
      () => { this.isLoadingResults = false; });
  }

  clearSearch(): void {
    this.searchStationForm.reset();
    this.searchStationForm.updateValueAndValidity();
    this.sharedService.clearSearch(this.sharedService.SEARCH_STATION_LS_KEY_SUFFIX);
  }

  private initForm(): void {
    const savedSearches = this.sharedService.getSearches();
    const savedQuery = savedSearches[this.sharedService.SEARCH_STATION_LS_KEY_SUFFIX];

    this.searchStationForm = this.fb.group({
      query: [savedQuery]
    });

    if (savedQuery) {
      this.searchStations();
    }

    this.searchStationForm.get('query').valueChanges.subscribe(() => {
      this.searchStations();
    });
  }
}
