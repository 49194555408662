import { authRoutesNames } from '../auth/auth.routes.names';
import { mainRoutesNames } from '../main/main.routes.names';

export const absoluteRoutesNames = {
    LOGIN: '/auth/' + authRoutesNames.LOGIN,
    LOGOUT: '/auth/' + authRoutesNames.LOGOUT,

    CONTACTS: '/app/' + mainRoutesNames.CONTACTS,
    FAVORITES: '/app/' + mainRoutesNames.FAVORITES,
    GROUPED_CONTACT: '/app/' + mainRoutesNames.GROUPED_CONTACT,
    HISTORY: '/app/' + mainRoutesNames.HISTORY,
    HOME: '/app/' + mainRoutesNames.HOME,
    INCIDENT_CARD: '/app/' + mainRoutesNames.INCIDENT_CARD,
    CAR_INCIDENT: '/app/' + mainRoutesNames.CAR_INCIDENT,
    NEW_INCIDENTS: '/app/' + mainRoutesNames.NEW_INCIDENTS,
    PROVIDERS: '/app/' + mainRoutesNames.PROVIDERS,
    STATION: '/app/' + mainRoutesNames.STATION
};
