import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { CoreModule } from '@ff/core';
import { LoaderModule } from '@ff/loader';
import { TranslateModule } from '@ngx-translate/core';
import { ContactsListComponent } from './components/contacts-list/contacts-list.component';
import { ProvidersListComponent } from './components/providers-list/providers-list.component';
import { StationsListComponent } from './components/stations-list/stations-list.component';
import { MaterialModule } from './material.module';
import { ContactBottomSheetComponent } from './components/contacts-list/contact-bottom-sheet/contact-bottom-sheet.component';
import { ProviderBottomSheetComponent } from './components/providers-list/provider-bottom-sheet/provider-bottom-sheet.component';


@NgModule({
    declarations: [
        ContactsListComponent,
        ProvidersListComponent,
        StationsListComponent,
        ContactBottomSheetComponent,
        ProviderBottomSheetComponent
    ],
    providers: [
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', floatLabel: 'auto' } }
    ],
    imports: [
        // Angular
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        // FF Packages
        LoaderModule,
        CoreModule,
        // Angular Material
        MaterialModule,

        // Others
        TranslateModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        // FF Packages
        LoaderModule,
        CoreModule,
        // Angular Material
        MaterialModule,

        // Others
        TranslateModule,

        ContactsListComponent,
        ProvidersListComponent,
        StationsListComponent
    ]
})
export class SharedModule {
    public constructor() { }
}
