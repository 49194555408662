<div class="items-list" *ngIf="hasProvidersToShow(); else noProvidersResultTemplate">
  <ng-container *ngFor="let provider of providers">
    <div *ngIf="isProviderDisplayed(provider)" class="item">
      <div class="d-flex justify-content-between align-content-end mb-1">
        <div class="item-name text">
          {{'entities.provider_types.'+ provider.provider_type.code | translate}}
        </div>
      </div>
      <div class="d-flex justify-content-between align-content-end">
        <div class="details-row mt-1">
          <div class="text light" [matTooltip]="provider.company">{{provider.company}}
          </div>
        </div>
        <div class="item-icons">
          <div class="fake-button-link">
            <mat-icon class="pointer" (click)="openProviderBottomSheet(provider)">contacts</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #noProvidersResultTemplate>
  <div class="text light my-3">{{'providers.search.results.providers_no_data' | translate}}</div>
</ng-template>
