<mat-card class="my-3">
  <mat-spinner class="mx-auto my-2" *ngIf="isLoadingData; else contactsListTemplate"></mat-spinner>
  <ng-template #contactsListTemplate>
    <div class="items-list" *ngIf="favoriteContacts?.length > 0; else noFavoriteContactsTemplate">
        <app-contacts-list [contacts]="favoriteContacts" [ignoreIsDisplayed]="true"></app-contacts-list>
    </div>

    <ng-template #noFavoriteContactsTemplate>
      <span class="text">{{'favorites.contacts.no_contacts' | translate}}</span>
    </ng-template>
  </ng-template>
</mat-card>
