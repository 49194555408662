<h3 class="mt-1 mb-2 text-center">
  {{providerData?.provider.company}}
</h3>

<mat-nav-list>
  <ng-container
    *ngFor="let phone of [providerData.provider.phone_1, providerData.provider.phone_2,providerData.provider.phone_3]">
    <ng-container *ngIf="phone">
      <!-- TODO: Add &body=... in link -->
      <a mat-list-item target="_blank" [href]="'sms:'+phone" *ngIf="isMobilePhone(phone)"
        (click)="clickToCall('sms', providerData.provider.id)">
        <div mat-line>
          <mat-icon color="primary" class="mr-3">sms</mat-icon>
          <div>
            <div>{{'general.contact_methods.sms' | translate}}</div>
            <div class="text light">{{phone}}</div>
          </div>
        </div>
      </a>
      <a mat-list-item target="_blank" [href]="'tel:'+phone"
        (click)="clickToCall(isMobilePhone(phone) ? 'cellphone' : 'phone', providerData.provider.id)">
        <div mat-line>
          <mat-icon color="primary" class="mr-3">{{isMobilePhone(phone) ? 'smartphone' : 'phone'}}
          </mat-icon>
          <div>
            <div>{{'general.contact_methods.phone' | translate}}</div>
            <div class="text light">{{phone}}</div>
          </div>
        </div>
      </a>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="providerData.provider.email">
    <a mat-list-item target="_blank" [href]="'mailto:'+providerData.provider.email"
      (click)="clickToCall('email', providerData.provider.id)">
      <div mat-line>
        <mat-icon color="primary" class="mr-3">email</mat-icon>
        <div>
          <div>{{'general.contact_methods.email' | translate}}</div>
          <div class="text light">{{providerData.provider.email}}</div>
        </div>
      </div>
    </a>
  </ng-container>
  <ng-container *ngIf="providerData.provider.email_2">
    <a mat-list-item target="_blank" [href]="'mailto:'+providerData.provider.email_2"
      (click)="clickToCall('email', providerData.provider.id)">
      <div mat-line>
        <mat-icon color="primary" class="mr-3">email</mat-icon>
        <div>
          <div>{{'general.contact_methods.email' | translate}}</div>
          <div class="text light">{{providerData.provider.email_2}}</div>
        </div>
      </div>
    </a>
  </ng-container>
</mat-nav-list>
