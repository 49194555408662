<h2 class="mb-3">{{'home.search.results.stations' | translate: {count: stations.length} }}</h2>
<mat-card class="mb-3">
  <div class="items-list" *ngIf="stations.length > 0; else noStationsResultTemplate">
    <div *ngFor="let station of stations | slice:0:limit" (click)="gotoStation(station.id)" class="item pointer">
      <div class="item-name text">{{station.site_name}}</div>
      <div class="d-flex justify-content-between align-content-end">
        <div class="d-flex align-items-center">
          <div class="mr-2" [ngClass]="'status-bullet ' + station.status.code"></div>
          <div class="text light">{{station.brand?.label}}</div>
        </div>
        <div class="text light">{{station.code_implant}}</div>
      </div>
    </div>
  </div>
  <ng-template #noStationsResultTemplate>
    <span class="text light">{{'home.search.results.stations_no_data' | translate}}</span>
  </ng-template>
</mat-card>
