import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StationsListItem } from 'src/app/models/stations-list-item.model';
import { endpoints } from 'src/environments/endpoints';
import { environment } from 'src/environments/environment';
import { Station } from './../../../models/station.model';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class StationService {

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  get(stationId: number): Observable<Station> {
    return this.http.get(
      `${environment.api.host}${endpoints.stations}/${stationId}`
    ).pipe(
      map(
        (res: any) => new Station(res.data.station)
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getFavorites(): Observable<StationsListItem[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.favorite_stations}`
    ).pipe(
      map(
        (res: any) => res.data.favorite_stations.map(favStation => new StationsListItem(favStation))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  updateFavorite(stationId: number): Observable<any> {
    return this.http.put(
      `${environment.api.host}${endpoints.favorite_stations}`, { station_id: stationId }
    ).pipe(
      map(
        (res: any) => res.data.is_favorite
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  searchForContact(searchInput: { sales_channel: string, zip_or_department: string, city: string }): Observable<Station[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.stations}${endpoints.search_multi}`, {
      params: searchInput
    }).pipe(
      map(
        (res: any) => res.data.stations.map(station => new Station(station))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }
}
