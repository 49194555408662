<div class="top-button">
  <button mat-flat-button color="primary" [disabled]="!canCreateReport()" (click)="generateReport()">
    <mat-icon>file_download</mat-icon>{{'history.create_report' | translate}}
  </button>
</div>

<mat-card>
  <form [formGroup]="searchForm">
    <div class="search-bar">
      <mat-form-field *ngIf="isAdminUser()">
        <mat-label>{{'history.search.user' | translate}}</mat-label>
        <mat-select formControlName="user_id">
          <mat-option [value]="-1">{{'history.search.all_users' | translate}}</mat-option>
          <ng-container *ngFor="let user of users">
            <mat-option *ngIf="!isVisitorOrVisitorDCM(user)" [value]="user.id">
              {{ getUsername(user) }}
            </mat-option>
          </ng-container>

        </mat-select>
      </mat-form-field>
      <mat-form-field class="disabled-input-datepicker" (click)="pickerStart.open()">
        <mat-label>{{'history.search.from'|translate}}</mat-label>
        <input matInput [matDatepicker]="pickerStart" formControlName="date_start" readonly>
        <div matSuffix class="d-flex align-items-center">
          <mat-datepicker-toggle [for]="pickerStart"></mat-datepicker-toggle>
        </div>
        <mat-datepicker #pickerStart></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="disabled-input-datepicker" (click)="pickerEnd.open()">
        <mat-label>{{'history.search.to'|translate}}</mat-label>
        <input matInput [matDatepicker]="pickerEnd" formControlName="date_end" readonly>
        <div matSuffix class="d-flex align-items-center">
          <mat-datepicker-toggle [for]="pickerEnd"></mat-datepicker-toggle>
        </div>
        <mat-datepicker #pickerEnd></mat-datepicker>
      </mat-form-field>
      <div class="results-info small alert alert-info" [innerHtml]="'history.results_info' | translate"></div>
    </div>
  </form>
</mat-card>

<mat-card class="w-100 mt-3">
  <h2>{{'history.list_title' | translate}}</h2>
  <app-history-card [ctc]="ctc" *ngFor="let ctc of ctcs"></app-history-card>
</mat-card>
