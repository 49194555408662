import { StationsListItem } from './../../../models/stations-list-item.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { endpoints } from './../../../../environments/endpoints';
import { environment } from './../../../../environments/environment';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  searchStations(query: string): Observable<StationsListItem[]> {
    return this.http.post(
      `${environment.api.host}${endpoints.stations + endpoints.search}`, { query }
    ).pipe(
      map(
        (res: any) => res.data.stations.map(station => new StationsListItem(station))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }
}
