<div class="items-list" *ngIf="hasContactsToShow(); else noContactsResultTemplate">
  <ng-container *ngFor="let contact of contacts">
    <div class="item" *ngIf="isContactDisplayed(contact)">
      <div class="d-flex justify-content-between align-content-end">
        <div class="item-name text">{{contact.name}}</div>
        <mat-icon class="favorite-star" (click)="updateFavoriteContact($event, contact.id)">
          {{contact.is_favorite? 'star' : 'star_outline'}}
        </mat-icon>
      </div>
      <div class="d-flex justify-content-between align-content-end">
        <div class="details-row mt-1">
          <div class="text" [matTooltip]="contact.contact_function?.label">
            {{contact.contact_function?.label}}&nbsp;{{contact.region | titlecase}}
          </div>
          <div class="text light" [matTooltip]="contact.department_canal">{{contact.department_canal}}</div>
        </div>
        <div class="item-icons">
          <div class="fake-button-link">
            <mat-icon class="pointer" (click)="openContactBottomSheet(contact)">contacts</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #noContactsResultTemplate>
  <span class="text light">{{'contacts.search.results.contacts_no_data' | translate}}</span>
</ng-template>
