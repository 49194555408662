import { BasicLabelledObject } from './basic-labelled-object.model';

// tslint:disable:variable-name
export class Contact {

  id: number;
  order?: number;
  function_id: number;
  contact_function: BasicLabelledObject;
  contact_type?: string;
  igg: string;
  type: string;
  name: string;
  email: string;
  registration_number: string;
  department_canal: string;
  cellphone: string;
  region: string;
  created_at: string;
  updated_at: string;

  is_favorite: boolean;
  stations_emails: string[];

  constructor(data = {}) {
    Object.assign(this, data);
    this.contact_function = new BasicLabelledObject(this.contact_function);
  }
}
