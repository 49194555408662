import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-reflex-sheet-dialog',
  templateUrl: './reflex-sheet-dialog.component.html',
  styleUrls: ['./reflex-sheet-dialog.component.scss']
})
export class ReflexSheetDialogComponent implements OnInit {

  reflexSheet: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ReflexSheetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    // N.B: This could be considered as security issue for XSS
    // but this data comes from the ADMIN part, secured by DigitalP@ss
    // If this still raises issues, note that not bypassing would cause styles not to be applied in reflex sheets display
    this.reflexSheet = this.sanitizer.bypassSecurityTrustHtml(this.data.reflexSheetText);
  }

}
