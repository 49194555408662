import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-history-card',
  templateUrl: './history-card.component.html',
  styleUrls: ['./history-card.component.scss']
})
export class HistoryCardComponent implements OnInit {

  @Input() ctc: any;

  constructor() { }

  ngOnInit(): void {
  }

  isMobile(phoneNumber: string): boolean {
    return phoneNumber.startsWith('06') || phoneNumber.startsWith('07');
  }
}
